import React from "react";
import {RouteComponentProps} from "react-router";
import Page from "../../utils/Page";
import {toMain} from "../moves";
import './ErrorView.scss'
import {mode} from "../../utils/Config";

export default class MainView extends React.Component<RouteComponentProps> {
  componentDidMount(): void {
    Page.setTitle('Ошибка');
    // @ts-ignore
    if (mode === 'prod')
      (async () => {
        await new Promise(() => {
          let interval = setInterval(() => {
            let captcha = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>;
            if (captcha[0]) {
              captcha[0].style.opacity = "0";
              clearInterval(interval);
            }
          }, 1000)
        })
      })()
  }

  render() {
    return <div className='error-view'>
      <h1>404 Страница не найдена</h1>
      <button
        className='go-home-button'
        onClick={() => toMain(this.props.history)}
      >
        Вернуться на главную страницу
      </button>
    </div>
  }
}