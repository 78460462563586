import React from "react";
import UserService from "../../../services/UserService";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import UserTable from "../../../components/table/user/UserTable";
import PopUp from "../../../components/popUp/PopUp";
import Loader from "../../../components/base/loader/Loader";
import HelpMessage from "../../../components/helpMessage/HelpMessage";
import UserEditFormContainer from "../form/UserEditFormContainer";

interface IUserTableContainerState {
  busy: boolean
  forbidden: boolean
  rows: Array<{
    values: App.User.IUserData,
    content: App.User.IUserContent
  }>
  popUp?: JSX.Element
  helpMessage: {
    refElement?: Element
    refElementRect?: DOMRect
    show: boolean
  }
}

export default class UserTableContainer extends React.Component<any, IUserTableContainerState>{
  private userService: UserService;

  static userRoles: App.IObject = {
    'user': 'Пользователь',
    'admin': 'Админ',
    'super_admin': 'Супер админ'
  };

  static userStatuses: App.IObject = {
    'inactive': 'Заблокированный',
    'queued': 'Неподтвержденный',
    'active': 'Активный'
  };

  constructor(props: any) {
    super(props);

    this.userService = new UserService();

    this.state = {
      busy: false,
      forbidden: false,
      rows: [],
      helpMessage: {
        show: false
      }
    }
  }

  componentDidMount(): void {
    let access = localStorage.getItem('access_token');
    let role = localStorage.getItem('role');
    if (!access || !['super_admin'].includes(role || ''))
      this.setState({
        forbidden: true
      })
    else
      this.getData();
  }

  async getData() {
    if (this.state.busy)
      return;

    this.setState({
      busy: true
    });

    let result = await this.userService.list();

    if (result && result.status === 200) {
      if (Array.isArray(result.response)) {
        let rows: Array<{
          values: App.User.IUserData,
          content: App.User.IUserContent
        }> = [];

        result.response.forEach((element: App.IObject) => {
          rows.push({
            values: {
              user_id: element['user_id'],
              user_email: element['user_email'],
              user_role: element['user_role'],
              user_status: element['user_status'],
            },
            content: {
              user_id: element['user_id'] || 0,
              user_email: element['user_email'] || '',
              user_role: UserTableContainer.userRoles[element['user_role']] || '',
              user_status: UserTableContainer.userStatuses[element['user_status']] || '',
            }
          })
        });

        this.setState(state =>({
          rows: rows
        }));

      } else
        this.setState({
          popUp: <ErrorPopUp />
        });

    } else if (result && result.status === 403) {
      this.setState({
        forbidden: true
      })
    } else if (result)
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
    else
      this.setState({
        forbidden: true
      })

    this.setState({
      busy: false
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  defineHelpMessageElement(element: Element, rect: DOMRect) {
    this.setState({
      helpMessage: {
        show: true,
        refElement: element,
        refElementRect: rect
      }
    });
  }

  hideHelpMessageElement() {
    if (this.state.helpMessage)
      this.setState(oldState => ({
        helpMessage: {
          ...oldState.helpMessage,
          show: false
        }
      }));
  }

  openEditForm(userId?: number) {
    let popUp = <UserEditFormContainer
      initialValues={userId ? this.state.rows.find(element => +element.values['user_id'] === +userId)?.values : undefined}
      responseCallback={this.getData.bind(this)}
      destroyElement={this.closePopUp.bind(this)}
    />;

    this.setState({
      popUp: popUp
    })
  }

  render() {
    return this.state.forbidden ?
      <h1 className='forbidden'>Нет доступа</h1>
      :
      <>
        <UserTable
          data={this.state.rows}
          edit={this.openEditForm.bind(this)}
          helpMessage={{
            define: this.defineHelpMessageElement.bind(this),
            hide: this.hideHelpMessageElement.bind(this)
          }}
        />
        {
          this.state.popUp ?
            <PopUp
              children={this.state.popUp}
              callback={this.closePopUp.bind(this)}
            />
            :
            <></>
        }
        {
          this.state.busy ?
            <div className='busy-window'>
              <div className='loader'>
                <Loader/>
              </div>
            </div>
            :
            <></>
        }
        <HelpMessage
          text={this.state.helpMessage.refElement?.getAttribute('data-hover')}
          {...this.state.helpMessage}
        />
      </>
  }
}