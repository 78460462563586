import React from "react";
import './HelpMessage.scss'

interface IHelpMessageProps {
  text?: string | JSX.Element | null
  refElement?: Element
  refElementRect?: DOMRect
  show?: boolean
}

interface IHelpMessageState {
  visible: boolean
  width: number
  triangleWidth: number
}

export default class HelpMessage extends React.Component<IHelpMessageProps, IHelpMessageState> {
  ref: React.RefObject<any>;
  constructor(props: IHelpMessageProps) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      visible: false,
      width: 350,
      triangleWidth: 20
    }
  }

  onMouseOver(event: React.MouseEvent<HTMLDivElement>) {
    if (event.relatedTarget === this.props.refElement ||
      event.relatedTarget === this.ref.current.children[0] ||
      event.relatedTarget === this.ref.current.children[1] ||
      event.relatedTarget === this.ref.current)
      this.setState({
        visible: true
      })
  }

  onMouseOut() {
    this.setState({
      visible: false
    })
  }

  render() {
    let CSRef = {
      left: 0,
      bottom: 0
    };
    if (this.ref.current)
       CSRef = this.ref.current.parentElement.getBoundingClientRect();
    let styles;
    let triangleStyles;
    let width = this.state.width;
    if (this.props.refElement && this.props.refElementRect) {
      let rect = this.props.refElementRect;
      let left = rect.left - (this.state.width - rect.width) / 2 - CSRef.left;
      let triangleLeft = (this.state.width - this.state.triangleWidth) / 2;

      if (left < 10) {
        left = rect.left - CSRef.left;
        triangleLeft = (rect.width - this.state.triangleWidth) / 2;

        if (left + this.state.width > window.innerWidth - 10)
          width = window.innerWidth - left - CSRef.left - 10;
      } else if (left + this.state.width > window.innerWidth - 10) {
        left = rect.right - CSRef.left - this.state.width;
        triangleLeft = this.state.width - (rect.width + this.state.triangleWidth) / 2;

        if (left + CSRef.left < 10) {
          width = this.state.width - (10 - left - CSRef.left);
          left = rect.right -CSRef.left - width;
          triangleLeft = width - (rect.width + this.state.triangleWidth) / 2;
        }
      }

      styles = {
        width: width,
        left: left,
        bottom: CSRef.bottom - rect.top
      };

      triangleStyles = {
        width: this.state.triangleWidth,
        height: this.state.triangleWidth,
        bottom: -this.state.triangleWidth / 2,
        left: triangleLeft
      };
    } else {
      styles = {
        width: width,
        left: -1000,
        bottom: -1000
      };
      triangleStyles = {
        width: this.state.triangleWidth,
        height: this.state.triangleWidth,
        bottom: -this.state.triangleWidth / 2,
        left: (width - this.state.triangleWidth) / 2
      };
    }

    let className = 'help-message';

    if ((!this.state.visible && !this.props.show) || !this.props.text)
      className += ' hidden';

    return <div
      ref={this.ref}
      style={styles}
      className={className}
      onMouseOver={this.onMouseOver.bind(this)}
      onMouseOut={this.onMouseOut.bind(this)}
      onTouchEnd={this.onMouseOut.bind(this)}
    >
      <div
        className='help-message-text'
        onMouseOver={this.onMouseOver.bind(this)}
        onMouseOut={this.onMouseOut.bind(this)}
        onTouchEnd={this.onMouseOut.bind(this)}
      >
          {this.props.text}
      </div>
      <div
        style={triangleStyles}
        className='help-message-triangle'
        onMouseOver={this.onMouseOver.bind(this)}
        onMouseOut={this.onMouseOut.bind(this)}
        onTouchEnd={this.onMouseOut.bind(this)}
      />
    </div>
  }
}