import React from "react";
import Select from "../base/customInput/select/Select";
import Button from "../base/button/Button";
import Status from "../../utils/Status";
import './StatusEditTool.scss'
import Loader from "../base/loader/Loader";

interface IStatusEditToolProps {
  requestId: number
  value: string
  changeStatus: (requestId: number, status: string) => void
  acceptChanges: (requestId: number) => Promise<any>
}

interface IStatusEditToolState {
  busy: boolean
}

export default class StatusEditTool extends React.Component<IStatusEditToolProps, IStatusEditToolState> {
  constructor(props: IStatusEditToolProps) {
    super(props);

    this.state = {
      busy: false
    }
  }

  async acceptChanges() {
    this.setState({
      busy: true
    });

    if (!this.state.busy)
      await this.props.acceptChanges(this.props.requestId);

    this.setState({
      busy: false
    })
  }

  render() {
    let options: Array<App.CustomSelect.IOption> = [];
    for (let key in Status.statusNameMap) {
      if (Status.statusNameMap.hasOwnProperty(key)) {
        options.push({
          id: key,
          label: Status.statusNameMap[key]
        })
      }
    }

    return <div className='status-edit-tool'>
      <div className='title'>Статус посылки</div>
      <div className='select-button-container'>
        <Select
          value={this.props.value}
          name='status'
          options={options}
          onChange={(name: string, value: string | number) => this.props.changeStatus(this.props.requestId, String(value))}
        />
        {
          this.state.busy ?
            <div className='status-edit-loader'><div className='loader'><Loader/></div></div>
            :
            <Button
              value='Применить'
              question={true}
              onClick={this.acceptChanges.bind(this)}
            />
        }
      </div>
    </div>
  }
}