import React from 'react';
import './SearchTool.scss'

interface ISearchToolProps {
  onInput: (event: React.ChangeEvent<HTMLInputElement>) => void
  search: () => void
}

export default class SearchTool extends React.Component<ISearchToolProps, any> {

  render() {
    return <div className='search-tool-container'>
      <div className='search-tool'>
        <div className='search-tool-label'>Поиск:</div>
        <input type='text' className='search-tool-input' placeholder='Введите запрос...' onChange={this.props.onInput}/>
        <div onClick={this.props.search} className='search-tool-button'/>
        <div className='search-tool-hint'>
          <div className='search-tool-hint-item'/>
          <div className='search-tool-hint-text'>
            <span>Поиск осуществляется по полям:</span><br/>
            <span className='bold'>Точка отправки</span><br/>
            <span className='bold'>Отправитель</span><br/>
            <span className='bold'>Телефон отправителя</span><br/>
            <span className='bold'>Почта</span><br/>
            <span className='bold'>Точка получения</span><br/>
            <span className='bold'>Получатель</span><br/>
            <span className='bold'>Телефон получателя</span><br/>
            <span className='bold'>Описание посылки</span><br/>
            <span className='bold'>Время формирования заявки</span><br/>
            <span className='bold'>Местоположение</span>
          </div>
        </div>
      </div>
    </div>
  }
}