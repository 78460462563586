import React from "react";
import Validator from "../../../utils/Validator";
import CustomInput from "../../base/customInput/CustomInput";
import Button from "../../base/button/Button";
import Modifier from "../../../utils/Modifier";
import Loader from "../../base/loader/Loader";
import '../Form.scss'
import './RestorePasswordForm.scss'

interface IRestorePasswordFormProps {
  button: string
  busy: boolean
  onSubmit: (data: App.IObject) => void
  step: number
  email?: string
}

interface IRestorePasswordFormState {
  inputs: {
    [key: string]: App.Form.IInputData
  }
}

export default class RestorePasswordForm extends React.Component<IRestorePasswordFormProps, IRestorePasswordFormState> {
  constructor(props: IRestorePasswordFormProps) {
    super(props);

    let state;

    if (this.props.step === 0)
      state = {
        inputs: {
          'email': {
            label: 'Почта:',
              tip: 'Пожалуйста, введите адрес электронной почты',
              type: 'email',
              valueType: 'email',
              required: true,
              value: '',
              valid: undefined
          },
        }
      };
    else
      state = {
        inputs: {
          'password': {
            label: 'Пароль:',
            tip: 'Пожалуйста, введите пароль',
            type: 'password',
            valueType: 'text',
            required: true,
            value: '',
            valid: undefined
          },
          'repeat_password': {
            label: 'Повторите пароль:',
            tip: 'Пожалуйста, введите пароль повторно',
            type: 'password',
            valueType: 'text',
            required: true,
            value: '',
            valid: undefined
          }
        }
      };

    this.state = state;
  }

  changeFormInputState(name: string, value: string | number) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].value = value;
      return {
        inputs: newStateInputs
      };
    });
  }

  removeValidation(name: string) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].valid = undefined;
      return {
        inputs: newStateInputs
      };
    })
  }

  setValidation(name: string) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].valid = this.validateInput(name, newStateInputs[name].value, newStateInputs[name].valueType, newStateInputs[name].required, newStateInputs);
      return {
        inputs: newStateInputs
      };
    });
  }

  showFormState() {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      for (let name in newStateInputs) {
        if (newStateInputs.hasOwnProperty(name)) {
          newStateInputs[name].valid = this.validateInput(name, newStateInputs[name].value, newStateInputs[name].valueType, newStateInputs[name].required, newStateInputs);
        }
      }
      return {
        inputs: newStateInputs
      };
    });
  }

  validateInput(name: string, value: number | string, valueType: string, required: boolean, newStateInputs: any) {
    if (((typeof value !== 'number') && value) || (typeof value === 'number')) {
      if (name === 'repeat_password')
        return Validator.validate(value, valueType) && value === newStateInputs['password'].value;
      else
        return Validator.validate(value, valueType);
    } else
      return !required;
  }

  getFormStateAndValues() {
    let formState = true;
    let formValues: App.IObject = {};
    for (let key in this.state.inputs) {
      if (this.state.inputs.hasOwnProperty(key)) {
        formState = formState && !!this.state.inputs[key].valid;
        if (this.state.inputs[key].valueType === 'phone')
          formValues[key] = Modifier.phoneMaskToValue(String(this.state.inputs[key].value));
        else
          formValues[key] = this.state.inputs[key].value;
      }
    }
    return {
      'state': formState,
      'values': formValues
    };
  }

  submit(event: React.FormEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showFormState();
    setTimeout(() => {
      let formStateAndValues = this.getFormStateAndValues();
      if (formStateAndValues.state) {
        this.props.onSubmit(formStateAndValues.values);
      }
    });
  }

  render() {
    return <form
      onSubmit={this.submit.bind(this)}
      className='restore-password-form'
    >
      <fieldset>
        <legend>Восстановление пароля</legend>
        {
          this.props.step ?
            <div className='restore-password-email'>
              {`Почта: ${this.props.email}`}
            </div>
            :
            <></>
        }
        {
          Object.keys(this.state.inputs).map((name) => {
            const state = this.state.inputs[name];
            return <CustomInput
              key={name}
              name={name}
              {...state}
              onFocus={this.removeValidation.bind(this)}
              onBlur={this.setValidation.bind(this)}
              onChange={this.changeFormInputState.bind(this)}
            />
          })
        }
      </fieldset>
      {
        this.props.busy ?
          <div className='loader'>
            <Loader/>
          </div>
          :
          <Button
            className='submit'
            submit={true}
            value={this.props.button}
          />
      }
    </form>
  }
}