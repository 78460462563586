import React from 'react';
import {Redirect, Route, RouteComponentProps, Switch} from "react-router-dom";
import HeaderView from "../../views/header/HeaderView";
import MainView from "../../views/main/MainView";
import routes from "../../views/routes";
import ParcelTableView from "../../views/parcel/table/ParcelTableView";
import AuthView from "../../views/auth/AuthView";
import ErrorView from "../../views/error/ErrorView";
import ParcelPageView from "../../views/parcel/page/ParcelPageView";
import LocationTableView from "../../views/location/table/LocationTableView";
import UserTableView from "../../views/user/table/UserTableView";
import NotificationControlView from "../../views/notification/control/NotificationControlView";
import ParcelTableForUserView from "../../views/parcel/tableForUser/ParcelTableForUserView";
import ChangeListView from "../../views/changeList/ChangeListView";
import '../../css/BusyWindow.scss';
import './App.scss';

class App extends React.Component<any, any>{
  getQueryParams(queryString: string) {
    queryString = queryString.substr(1);
    let paramStrings: Array<string> = queryString.split('&');
    let params: App.IObject = {};
    paramStrings.forEach((paramString) => {
      let split = paramString.split('=');
      if (split.length === 2) {
        params[split[0]] = split[1];
      }
    })
    return params;
  }

  render() {
    return (
      <div className="App">
        <Route component={HeaderView} />
        <Switch>
          <Route exact path='/'>
            <Redirect to={routes.main}/>
          </Route>
          <Route exact path={routes.main} component={MainView} />
          <Route exact path={routes.parcels} component={ParcelTableView}/>
          <Route exact path={routes.signIn} component={AuthView}/>
          <Route exact path={routes.signUp} component={AuthView}/>
          <Route exact path={routes.myParcels} component={ParcelTableForUserView}/>
          <Route exact path={routes.locations} component={LocationTableView}/>
          <Route exact path={routes.users} component={UserTableView}/>
          <Route exact path={routes.notifications} component={NotificationControlView}/>
          <Route exact path={routes.changeList} component={ChangeListView}/>
          <Route path={routes.confirm} render={(props: RouteComponentProps<any>) => {
            let queryString = props.location.search;
            let queryParams = this.getQueryParams(queryString);
            if (queryParams['code']) {
              window.location.href = `/api/auth/confirm?code=${queryParams['code']}`;
              return <></>
            } else
              return <Redirect to={routes.error}/>
          }}>
          </Route>
          <Route path={routes.restore} component={AuthView}/>
          <Route path={routes.parcel} component={ParcelPageView}/>
          <Route path={routes.oldParcel} render={(props: RouteComponentProps<{hash: string}>) => {
            return <Redirect to={`/parcel/${props.match.params.hash}`}/>;
          }}>
          </Route>
          <Route path={routes.error} component={ErrorView}/>
          <Route component={ErrorView}/>
        </Switch>
      </div>
    );
  }
}

export default App;
