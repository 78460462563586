import React from "react";
import Validator from "../../../utils/Validator";
import CustomInput from "../../base/customInput/CustomInput";
import Button from "../../base/button/Button";
import Modifier from "../../../utils/Modifier";
import Loader from "../../base/loader/Loader";
import QuestionPopUp from "../../popUp/questionPopUp/QuestionPopUp";
import '../Form.scss'

interface IParcelFormProps {
  button: string
  legend: string
  busy: boolean
  onSubmit: (data: App.IObject) => void
  locations?: Array<App.Location.ILocationOption>
  initialValues?: App.IObject
}

interface IParcelFormState {
  inputs: {
    [key: string]: App.Form.IInputData
  }
  popUp?: JSX.Element
}

export default class ParcelForm extends React.Component<IParcelFormProps, IParcelFormState> {
  constructor(props: IParcelFormProps) {
    super(props);

    this.state = {
      inputs: {}
    }
  }

  componentDidMount(): void {
    let inputs: {
      [key: string]: App.Form.IInputData
    } = {
      'from_address': {
        label: 'Точка отправки:',
        tip: 'Пожалуйста, выберите точку отправки',
        type: 'select',
        valueType: 'select',
        required: true,
        options: this.props.locations,
        value: '',
        valid: undefined
      },
      'sender': {
        label: 'Отправитель:',
        tip: 'Пожалуйста, введите отправителя',
        type: 'text',
        valueType: 'text',
        required: true,
        value: '',
        valid: undefined
      },
      'sender_phone': {
        label: 'Телефон отправителя:',
        tip: 'Пожалуйста, введите телефон отправителя',
        type: 'text',
        valueType: 'phone',
        required: true,
        value: '',
        valid: undefined
      },
      'sender_email': {
        label: 'Почта отправителя:',
        tip: 'Пожалуйста, введите адрес электронной почты отправителя',
        type: 'email',
        valueType: 'email',
        required: true,
        value: '',
        valid: undefined
      },
      'to_address': {
        label: 'Точка получения:',
        tip: 'Пожалуйста, выберите точку получения',
        type: 'select',
        valueType: 'select',
        required: true,
        options: this.props.locations,
        value: '',
        valid: undefined
      },
      'receiver': {
        label: 'Получатель:',
        tip: 'Пожалуйста, введите получателя',
        type: 'text',
        valueType: 'text',
        required: true,
        value: '',
        valid: undefined
      },
      'receiver_phone': {
        label: 'Телефон получателя:',
        tip: 'Пожалуйста, введите телефон получателя',
        type: 'text',
        valueType: 'phone',
        required: true,
        value: '',
        valid: undefined
      },
      'receiver_email': {
        label: 'Почта получателя:',
        tip: 'Пожалуйста, введите адрес электронной почты получателя',
        type: 'email',
        valueType: 'email',
        required: false,
        value: '',
        valid: undefined
      },
      'item': {
        label: 'Описание посылки:',
        tip: 'Пожалуйста, введите описание посылки',
        type: 'text',
        valueType: 'text',
        required: true,
        value: '',
        valid: undefined
      },
      'agreed': {
        label: 'Согласовано с:',
        tip: 'Пожалуйста, введите ФИО человека, с которым согласовано',
        type: 'text',
        valueType: 'text',
        required: true,
        value: '',
        valid: undefined
      },
      'comments': {
        label: 'Комментарий:',
        tip: 'Пожалуйста, введите комментарий',
        type: 'text',
        valueType: 'text',
        required: false,
        value: '',
        valid: undefined
      }
    };

    inputs = this.setOptionsToInputs(inputs);

    if (this.props.initialValues) {
      for (let key in inputs) {
        if (inputs.hasOwnProperty(key) && this.props.initialValues.hasOwnProperty(key) && this.props.initialValues[key]) {

          if (inputs[key].valueType === 'phone')
            inputs[key].value = Modifier.phoneValueToMask(String(this.props.initialValues[key]));
          else
            inputs[key].value = this.props.initialValues[key];

          inputs[key].valid = Validator.validate(inputs[key].value, inputs[key].valueType);
        }
      }
    }

    this.setState({
      inputs: inputs
    })
  }

  componentDidUpdate(prevProps: Readonly<IParcelFormProps>, prevState: Readonly<IParcelFormState>): void {
    if (prevProps.locations !== this.props.locations) {
      this.setState((oldState) => {
        let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
        newStateInputs = this.setOptionsToInputs(newStateInputs);
        return {
          inputs: newStateInputs
        };
      })
    }
  }

  setOptionsToInputs(inputs: {
    [key: string]: App.Form.IInputData
  }) {
    Object.keys(inputs).forEach(name => {
      //Find selects
      if (inputs[name].valueType === 'select') {

        //If there are locations, define options for selects
        if (this.props.locations?.length) {

          let options: Array<App.CustomSelect.IOption> = [];

          //Obtain active locations from list
          this.props.locations.forEach((element: App.Location.ILocationOption) => {
            if (element.status)
              options.push({
                id: element.id,
                label: element.label
              })
          });

          //If select has initial value, add this value as disabled option to list else set active locations as options for select
          if (this.props.initialValues && this.props.initialValues[name]) {
            let selectInitialValue = this.props.initialValues[name];

            //If there is no already such option
            if (!options.find(element => +element.id === +selectInitialValue)) {

              let selectInitialLocation = this.props.locations.find(element => +element.id === +selectInitialValue);
              if (selectInitialLocation) {
                let disabledOption: App.CustomSelect.IOption = {
                  id: selectInitialLocation.id,
                  label: selectInitialLocation.label,
                  disabled: true
                };
                options.unshift(disabledOption);
                inputs[name].options = options;
              }
            } else
              inputs[name].options = options;
          } else
            inputs[name].options = options;
        } else
          inputs[name].options = undefined;
      }
    });

    return inputs;
  }

  changeFormInputState(name: string, value: string | number) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].value = value;

      if (newStateInputs[name].valueType === 'select') {
        Object.keys(newStateInputs).forEach(key => {
          if (newStateInputs[key].valueType === 'select' && key !== name) {
            newStateInputs[key].options?.forEach((option: App.CustomSelect.IOption) => {
              option.selected = +option.id === +value;
            })
          }
        });
      }

      return {
        inputs: newStateInputs
      };
    });
  }

  removeValidation(name: string) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].valid = undefined;
      return {
        inputs: newStateInputs
      };
    })
  }

  setValidation(name: string) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].valid = this.validateInput(newStateInputs[name].value, newStateInputs[name].valueType, newStateInputs[name].required);
      return {
        inputs: newStateInputs
      };
    });
  }

  showFormState() {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      for (let name in newStateInputs) {
        if (newStateInputs.hasOwnProperty(name))
          newStateInputs[name].valid = this.validateInput(newStateInputs[name].value, newStateInputs[name].valueType, newStateInputs[name].required);
      }
      return {
        inputs: newStateInputs
      };
    });
  }

  validateInput(value: number | string, valueType: string, required: boolean) {
    if (valueType === 'select')
      return !!this.props.locations?.find(element => element.id === value);
    else if (((typeof value !== 'number') && value) || (typeof value === 'number'))
      return Validator.validate(value, valueType);
    else
      return !required;
  }

  getFormStateAndValues() {
    let formState = true;
    let formValues: App.IObject = {};

    for (let key in this.state.inputs) {
      if (this.state.inputs.hasOwnProperty(key)) {
        formState = formState && !!this.state.inputs[key].valid;

        if (this.state.inputs[key].valueType === 'phone')
          formValues[key] = Modifier.phoneMaskToValue(String(this.state.inputs[key].value));
        else
          formValues[key] = this.state.inputs[key].value;
      }
    }

    return {
      'state': formState,
      'values': formValues
    };
  }

  submit(event: React.FormEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showFormState();
    setTimeout(() => {
      let formStateAndValues = this.getFormStateAndValues();
      if (formStateAndValues.state) {
        this.setState({
          popUp: <QuestionPopUp
            acceptAction={() => this.props.onSubmit(formStateAndValues.values)}
            declineAction={this.closePopUp.bind(this)}
          />
        })
      }
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  render() {
    return <form
      onSubmit={this.submit.bind(this)}
    >
      <fieldset>
        <legend>
          {this.props.legend}
        </legend>
        {
          Object.keys(this.state.inputs).map((name) => {
            const state = this.state.inputs[name];
            return <CustomInput
              key={name}
              name={name}
              {...state}
              onFocus={this.removeValidation.bind(this)}
              onBlur={this.setValidation.bind(this)}
              onChange={this.changeFormInputState.bind(this)}
            />
          })
        }
      </fieldset>
      {
        this.props.busy ?
          <div className='loader'>
            <Loader />
          </div>
          :
          <Button
            className='submit'
            submit={true}
            value={this.props.button}
          />
      }
      {
        this.state.popUp ?
          this.state.popUp
          :
          <></>
      }
    </form>
  }
}