import React from "react";
import LocationHistoryTable from "../LocationHistoryTable";
import './LocationHistoryRow.scss'
import Modifier from "../../../utils/Modifier";
import QuestionPopUp from "../../popUp/questionPopUp/QuestionPopUp";

interface ILocationHistoryRowProps {
  requestId: number
  data: App.Location.ILocationHistory
  onClick: (requestLocationId: number, requestId: number, locationId: number) => void
  defineHelpMessageElement: (element: Element, rect: DOMRect) => void
  hideHelpMessage: () => void
  deleteAllowed?: boolean
}

interface ILocationHistoryRowState {
  popUp?: JSX.Element
}

export default class LocationHistoryRow extends React.Component<ILocationHistoryRowProps, ILocationHistoryRowState> {
  ref: App.IObject;
  constructor(props: ILocationHistoryRowProps) {
    super(props);

    this.ref = {};

    this.state = {}
  }

  onClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
      this.setState({
        popUp: <QuestionPopUp
          acceptAction={() => this.props.onClick(this.props.data.request_location_id, this.props.requestId, this.props.data.location_id)}
          declineAction={this.closePopUp.bind(this)}
        />
      })
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  render() {
    let cells = [];
    for (let key in LocationHistoryTable.locationHistoryTableTitles) {
      if (LocationHistoryTable.locationHistoryTableTitles.hasOwnProperty(key)) {
        this.ref[key] = React.createRef();
        const cellData = key === 'location_time' ?
          Modifier.formatTimestamp(Number(this.props.data[key])) || ''
          :
          // @ts-ignore
          this.props.data[key] || ''

        cells.push((<div
          ref={this.ref[key]}
          key={key}
          className={key}
          data-hover={cellData}
          onMouseOver={() => this.props.defineHelpMessageElement(this.ref[key].current, this.ref[key].current.getBoundingClientRect())}
          onMouseOut={this.props.hideHelpMessage}
        >
          {cellData}
        </div>))
      }
    }
      return <div className='location-history-row'>
        {cells}
        {
          this.props.deleteAllowed !== false ?
            <div
              className='delete-location-button'
              onClick={this.onClick.bind(this)}
            >
              {
                this.state.popUp ?
                  this.state.popUp
                  :
                  <></>
              }
            </div>
            :
            <></>
        }
    </div>
  }
}