import Network from "../utils/Network";
import Token from "../utils/Token";
import {mode} from "../utils/Config";

export default class AuthService {
  private network: Network;

  constructor() {
    this.network = Network.getInstance();
  }

  async logout() {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {
      let sessionId = localStorage.getItem('session');
      if (!sessionId) return;
      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return this.network.postRequest('auth/logout', {
        'session_id': +sessionId
      }, headers)
    }
  }

  async signIn(data: App.IObject) {
    // @ts-ignore
    if (mode === 'prod')
      // @ts-ignore
      data['captcha'] = await grecaptcha.execute('6LfqTrgUAAAAAM48tLy4TBsSauz1z1P3tFytrWWC', {action: 'send_form'});
    return await this.network.postRequest('auth/sign-in', data);
  }

  async signUp(data: App.IObject) {
    // @ts-ignore
    if (mode === 'prod')
      // @ts-ignore
      data['captcha'] = await grecaptcha.execute('6LfqTrgUAAAAAM48tLy4TBsSauz1z1P3tFytrWWC', {action: 'send_form'});
    return await this.network.postRequest('auth/sign-up', data);
  }

  async restore(data: App.IObject) {
    // @ts-ignore
    if (mode === 'prod')
      // @ts-ignore
      data['captcha'] = await grecaptcha.execute('6LfqTrgUAAAAAM48tLy4TBsSauz1z1P3tFytrWWC', {action: 'send_form'});
    return await this.network.postRequest('auth/restore', data);
  }

  async setNewPassword(data: App.IObject) {
    // @ts-ignore
    if (mode === 'prod')
      // @ts-ignore
      data['captcha'] = await grecaptcha.execute('6LfqTrgUAAAAAM48tLy4TBsSauz1z1P3tFytrWWC', {action: 'send_form'});
    return await this.network.postRequest('auth/new-password', data);
  }

  async checkRestoreUrl(hash: string) {
    return this.network.getRequest(`auth/check-restore-url?token=${hash}`);
  }
}