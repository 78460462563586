import React from "react";
import Validator from "../../../utils/Validator";
import CustomInput from "../../base/customInput/CustomInput";
import Button from "../../base/button/Button";
import Loader from "../../base/loader/Loader";
import {Link} from "react-router-dom";
import routes from "../../../views/routes";
import '../Form.scss'
import './SignInForm.scss'

interface ISignInFormProps {
  busy: boolean
  onSubmit: (data: App.IObject) => void
  initialValues?: App.IObject
}

interface ISignInFormState {
  inputs: {
    [key: string]: App.Form.IInputData
  }
}

export default class SignInForm extends React.Component<ISignInFormProps, ISignInFormState> {
  constructor(props: ISignInFormProps) {
    super(props);

    this.state = {
      inputs: {
        'email': {
          label: 'Почта:',
          tip: 'Пожалуйста, введите адрес электронной почты',
          type: 'email',
          valueType: 'email',
          required: true,
          value: this.props.initialValues?.['email'] || '',
          valid: this.props.initialValues?.['email'] ? Validator.validate(this.props.initialValues?.['email'], 'email') : undefined
        },
        'password': {
          label: 'Пароль:',
          tip: 'Пожалуйста, введите пароль',
          type: 'password',
          valueType: 'text',
          required: true,
          value: this.props.initialValues?.['password'] || '',
          valid: this.props.initialValues?.['password'] ? Validator.validate(this.props.initialValues?.['password'], 'text') : undefined
        }
      }
    }
  }

  changeFormInputState(name: string, value: string | number) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].value = value;

      return {
        inputs: newStateInputs
      };
    });
  }

  removeValidation(name: string) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].valid = undefined;
      return {
        inputs: newStateInputs
      };
    })
  }

  setValidation(name: string) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].valid = this.validateInput(newStateInputs[name].value, newStateInputs[name].valueType, newStateInputs[name].required);
      return {
        inputs: newStateInputs
      };
    });
  }

  showFormState() {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      for (let name in newStateInputs) {
        if (newStateInputs.hasOwnProperty(name))
          newStateInputs[name].valid = this.validateInput(newStateInputs[name].value, newStateInputs[name].valueType, newStateInputs[name].required);
      }
      return {
        inputs: newStateInputs
      };
    });
  }

  validateInput(value: number | string, valueType: string, required: boolean) {
    if (((typeof value !== 'number') && value) || (typeof value === 'number'))
      return Validator.validate(value, valueType);
    else
      return !required;
  }

  getFormStateAndValues() {
    let formState = true;
    let formValues: App.IObject = {};
    for (let key in this.state.inputs) {
      if (this.state.inputs.hasOwnProperty(key)) {
        formState = formState && !!this.state.inputs[key].valid;
        formValues[key] = this.state.inputs[key].value;
      }
    }
    return {
      'state': formState,
      'values': formValues
    };
  }

  submit(event: React.FormEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showFormState();
    setTimeout(() => {
      let formStateAndValues = this.getFormStateAndValues();
      if (formStateAndValues.state) {
        this.props.onSubmit(formStateAndValues.values);
      }
    });
  }

  render() {
    return <form
      onSubmit={this.submit.bind(this)}
      className='sign-in-form'
    >
      <fieldset>
        {
          Object.keys(this.state.inputs).map((name) => {
            const state = this.state.inputs[name];
            return <CustomInput
              key={name}
              name={name}
              {...state}
              onFocus={this.removeValidation.bind(this)}
              onBlur={this.setValidation.bind(this)}
              onChange={this.changeFormInputState.bind(this)}
            />
          })
        }
        <Link to={routes.restore} className='restore-password'>Забыли пароль?</Link>
      </fieldset>
      {
        this.props.busy ?
          <div className='loader'>
            <Loader/>
          </div>
          :
          <Button
            className='submit'
            value='Продолжить'
            submit={true}
          />
      }
    </form>
  }
}