import React from 'react';
import LocationTable from "../../LocationTable";
import EditButton from "../../../../base/button/editButton/EditButton";
import './LocationTableRow.scss'

interface ILocationTableRowProps {
  data: App.Location.ILocationContent
  edit: (locationId: number) => void
  helpMessage: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
}

export default class LocationTableRow extends React.Component<ILocationTableRowProps, any> {
  ref: App.IObject;
  constructor(props: ILocationTableRowProps) {
    super(props);
    this.ref = {};
  }

  render() {
    let cells = [];
    for (let key in LocationTable.locationTableTitles) {
      if (this.props.data.hasOwnProperty(key) && LocationTable.locationTableTitles.hasOwnProperty(key)) {
        let cell: string;
        // @ts-ignore
        cell = this.props.data[key];
        this.ref[key] = React.createRef();
        cells.push((
          <div
            ref={this.ref[key]}
            key={key}
            className={key}
            data-hover={
              // @ts-ignore
              this.props.data[key] || ''
            }
            onMouseOver={() => this.props.helpMessage.define(this.ref[key].current, this.ref[key].current.getBoundingClientRect())}
            onMouseOut={this.props.helpMessage.hide}
          >
            {cell}
          </div>
        ));
      }
    }

    cells.push((
      <EditButton key={this.props.data['location_id']} onClick={() => this.props.edit(this.props.data['location_id'])} />
    ));

    return <div className='location-table-row'>
      {cells}
    </div>
  }
}