import React from "react";
import './CustomInput.scss'
import Input from "./input/Input";
import Select from "./select/Select"

interface ICustomInputProps {
  name: string
  value: string | number
  label: string
  tip: string
  valid: boolean | undefined
  type: string
  valueType: string
  options?: Array<App.CustomSelect.IOption>
  onBlur: (name: string) => void
  onFocus: (name: string) => void
  onChange: (name: string, value: string | number) => void
}

interface ICustomInputState {
  slide: boolean
}

export default class CustomInput extends React.Component<ICustomInputProps, ICustomInputState> {
  constructor(props: ICustomInputProps) {
    super(props);

      this.state = {
      slide: (!((typeof this.props.value !== 'number') && !this.props.value)),
    };
  }

  static inputValueTypes = ['email', 'text', 'phone'];
  static selectValueTypes = ['select'];

  slideUp() {
    if (!this.state.slide)
      this.setState({
        slide: true
      })
  }

  slideDown() {
    if ((typeof this.props.value !== 'number') && !this.props.value)
      this.setState({
        slide: false
      })
  }

  onFocus(name: string) {
    this.props.onFocus(name);
    setTimeout(() => this.slideUp());
  }

  onBlur(name: string) {
    this.props.onBlur(name);
    setTimeout(() => this.slideDown());
  }

  render() {
    let containerClassName = 'container';
    if (this.props.valid === true)
      containerClassName += ' valid';
    else if (this.props.valid === false)
      containerClassName += ' invalid';

    let input = <></>;
    if (CustomInput.inputValueTypes.includes(this.props.valueType))
      input = <Input
        {...this.props}
        onFocus={this.onFocus.bind(this)}
        onBlur={this.onBlur.bind(this)}
      />;
    else if (CustomInput.selectValueTypes.includes(this.props.valueType))
      input = <Select
        {...this.props}
        onFocus={this.onFocus.bind(this)}
        onBlur={this.onBlur.bind(this)}
        />;

    return <div className='custom-input'>
      <div className={containerClassName}>
        {input}
        {
          this.props.valid === false ?
            <div className='tip'>
              {this.props.tip}
            </div> :
            <></>
        }
      </div>
      <div className={`label ${this.state.slide ? 'label-slide' : 'label-start'}`}>
        {this.props.label}
      </div>
    </div>
  }
}