import React from 'react';
import UserTable from "../../UserTable";
import EditButton from "../../../../base/button/editButton/EditButton";
import './UserTableRow.scss'

interface IUserTableRowProps {
  data: App.User.IUserContent
  edit: (userId: number) => void
  helpMessage: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
}

export default class UserTableRow extends React.Component<IUserTableRowProps, any> {
  ref: App.IObject;
  constructor(props: IUserTableRowProps) {
    super(props);
    this.ref = {};
  }

  render() {
    let cells = [];
    for (let key in UserTable.userTableTitles) {
      if (this.props.data.hasOwnProperty(key) && UserTable.userTableTitles.hasOwnProperty(key)) {
        let cell: string;
        // @ts-ignore
        cell = this.props.data[key];
        this.ref[key] = React.createRef();
        cells.push((
          <div
            ref={this.ref[key]}
            key={key}
            className={key}
            data-hover={
              // @ts-ignore
              this.props.data[key] || ''
            }
            onMouseOver={() => this.props.helpMessage.define(this.ref[key].current, this.ref[key].current.getBoundingClientRect())}
            onMouseOut={this.props.helpMessage.hide}
          >
            {cell}
          </div>
        ));
      }
    }

    cells.push((
      <EditButton key={this.props.data['user_id']} onClick={() => this.props.edit(this.props.data['user_id'])} />
    ));

    return <div className='user-table-row'>
      {cells}
    </div>
  }
}