export default class Validator {
  static validate(value: string | number, type?: string | null) {
    if (!type)
      type = 'text';

    return Validator.rules[type].test(String(value));
  }

  static rules: {
    [key: string]: RegExp
  } = {
    text: /^.*$/,
    email: /^[A-z0-9._-]+@(aps.by|renault.by|renault-belarus.by|lada.by|gmail.com|mail.ru)$/,
    phone: /^\+375\(\d{2}\)\d{3}-\d{2}-\d{2}$/,
    select: /^[A-z_0-9]*$/
  };
}