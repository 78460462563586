import React from "react";
import './ErrorPopUp.scss'

interface IErrorPopUpProps {
  errors?: Array<any> | string
}

export default class ErrorPopUp extends React.Component<IErrorPopUpProps, any> {
  render() {
    return <div className='error-container'>
      {
        Array.isArray(this.props.errors) ?
          this.props.errors.map((error: any, index: number) => {
            if (typeof error === 'string')
              return <h1 key={index} className='error'>
                {error}
              </h1>;
            else
              return <></>
          })
          :
          <h1 className='error'>{this.props.errors || 'Непредвиденная ошибка.'}</h1>
      }
    </div>;
  }
}