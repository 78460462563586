import React from "react";
import ParcelTable from "../../../ParcelTable";
import Status from "../../../../../../utils/Status";
import './RowShortView.scss'

interface IRowShortViewProps {
  data: App.IObject
  helpMessage: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
}

export default class RowShortView extends React.Component<IRowShortViewProps, any> {
  ref: App.IObject;
  constructor(props: IRowShortViewProps) {
    super(props);
    this.ref = {};
  }

  render() {
    let cells = [];
    for (let key in ParcelTable.parcelTableTitles) {
      if (this.props.data.hasOwnProperty(key) && ParcelTable.parcelTableTitles.hasOwnProperty(key)) {
        let cell: JSX.Element | string;
        if (key === 'status')
          cell = Status.getHtml(this.props.data[key]);
        else
          cell = this.props.data[key];
        this.ref[key] = React.createRef();
        cells.push((
          <div
            ref={this.ref[key]}
            key={key}
            className={key}
            data-hover={this.props.data[key] || ''}
            onMouseOver={() => this.props.helpMessage.define(this.ref[key].current, this.ref[key].current.getBoundingClientRect())}
            onMouseOut={this.props.helpMessage.hide}
          >
            {cell}
          </div>
        ));
      }
    }
    return <div className='row-short-view'>
      {
        cells
      }
    </div>
  }
}