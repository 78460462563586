import React from "react";
import Select from "../base/customInput/select/Select";
import Loader from "../base/loader/Loader";
import Button from "../base/button/Button";
import './UserAddTool.scss'

interface IUserAddToolProps {
  locationId: number
  userList?: Array<App.User.IUserOption>
  busy: boolean
  add: (locationId: number, userId: number) => void
}

interface IUserAddToolState {
  selected: number
  disabled: boolean
}

export default class UserAddTool extends React.Component<IUserAddToolProps, IUserAddToolState> {
  constructor(props: IUserAddToolProps) {
    super(props);

    this.state = {
      selected: 0,
      disabled: true
    }
  }

  componentDidUpdate(prevProps: Readonly<IUserAddToolProps>, prevState: Readonly<IUserAddToolState>, snapshot?: any): void {
    if ((prevProps.locationId !== this.props.locationId) ||
      (prevProps.userList !== this.props.userList))
      this.setState({
        selected: 0,
        disabled: true
      })
  }

  changeSelectedUser(name: string, value: string | number) {
    this.setState({
      selected: +value,
      disabled: !+value
    })
  }

  render() {
    let options: Array<App.CustomSelect.IOption> = [{
      id: 0,
      label: 'Выберите пользователя',
      disabled: true
    }];
    this.props.userList?.forEach((user) => {
      options.push({
        id: user.id,
        label: user.label,
        disabled: user.disabled
      });
    });
    return <div className='user-add-tool'>
      <Select
        value={this.state.selected}
        name='user'
        options={options}
        onChange={this.changeSelectedUser.bind(this)}
      />
      {
        this.props.busy ?
          <div className='add-user-loader'><div className='loader'><Loader/></div></div>
          :
          <Button
            disabled={this.state.disabled}
            question={true}
            value='Добавить'
            onClick={() => this.props.add(this.props.locationId, this.state.selected)}
          />
      }
    </div>
  }
}