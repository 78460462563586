import {baseUrl} from "./Config";

export default class Modifier {
  static setZeroToDigit(digit: number | string) {
    return ('0' + digit).slice(-2);
  }

  static phoneMaskToValue(mask: string) {
    return `${mask.substr(5, 2)}${mask.substr(8, 3)}${mask.substr(12, 2)}${mask.substr(15, 2)}`;
  }

  static phoneValueToMask(value: string) {
    return `+375(${value.substr(0, 2)})${value.substr(2, 3)}-${value.substr(5, 2)}-${value.substr(7, 2)}`;
  }

  static formatTimestamp(value: number) {
    if (!value)
      return value;
    const newDate = new Date(value * 1000);
    const newDay = Modifier.setZeroToDigit(newDate.getDate());
    const newMonth = Modifier.setZeroToDigit((newDate.getMonth() + 1));
    const newHours = Modifier.setZeroToDigit(newDate.getHours());
    const newMinutes = Modifier.setZeroToDigit(newDate.getMinutes());

    return `${newDay}.${newMonth}.${newDate.getFullYear()} ${newHours}:${newMinutes}`;
  }

  static parcelHashToUrl(hash: string) {
    return `${baseUrl}/parcel/${hash}`;
  }
}