import React from 'react'
import UserService from "../../services/UserService";
import Header from "../../components/header/Header";
import AuthService from "../../services/AuthService";
import ParcelService from "../../services/ParcelService";

interface IHeaderContainerProps {
  active: string
  toMain: () => void
}

interface IHeaderContainerState {
  role?: string
  amountOfChanges: number
}

export default class HeaderContainer extends React.Component<IHeaderContainerProps, IHeaderContainerState> {
  private userService: UserService;
  private authService: AuthService;
  private parcelService: ParcelService;

  constructor(props: IHeaderContainerProps) {
    super(props);

    this.userService = new UserService();
    this.authService = new AuthService();
    this.parcelService = new ParcelService();

    this.state = {
      role: undefined,
      amountOfChanges: 0
    }
  }

  componentDidMount(): void {
    this.defineRole();
    this.getAmountOfChanges();
  }

  componentDidUpdate(prevProps: Readonly<IHeaderContainerProps>, prevState: Readonly<any>): void {
    if (this.props.active !== prevProps.active) {
      this.defineRole();
      this.getAmountOfChanges();
    }
  }

  defineRole() {
    this.userService.role().then((result) => {
      if (result && result.status === 200)
        this.setState({
          role: result.response.role
        });
      else
        this.setState({
          role: undefined
        });
    })
  }

  getAmountOfChanges() {
    this.parcelService.amountOfChanges().then((result) => {
      if (result && result.status === 200)
        this.setState({
          amountOfChanges: result.response['amount_of_changes']
        });
      else
        this.setState({
          amountOfChanges: 0
        })
    })
  }

  logout() {
    this.authService.logout().then(() => {
      localStorage.clear();
      this.defineRole();
      this.props.toMain();
    });
  }

  render() {
    return <Header
      active={this.props.active}
      role={this.state.role}
      logout={this.logout.bind(this)}
      toMain={this.props.toMain}
      amountOfChanges={this.state.amountOfChanges}
      />
  }
}