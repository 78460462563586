import Network from "../utils/Network";
import Token from "../utils/Token";


export default class LocationService {
  network: Network;
  constructor() {
    this.network = Network.getInstance();
  }

  async getAll() {
    let result = await this.network.getRequest('locations');
    let locations: Array<App.Location.ILocationOption> = [];
    if (result.status === 200)
      result.response.forEach((element: App.Location.ILocationData) => {
        locations.push({
          id: element.location_id,
          label: `${element.location_name} (${element.location_address})`,
          status: element.location_status
        })
      });
    else
      locations = [];

    return locations;
  }

  async list() {
    return await this.network.getRequest('locations');
  }

  async new(data: App.IObject) {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return this.network.postRequest(`location/new`, data, headers);
    }
  }

  async edit(data: App.IObject, locationId: number) {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return this.network.postRequest(`location/edit/${locationId}`, data, headers);
    }
  }
}