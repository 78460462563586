import React from "react";
import Modifier from "../../utils/Modifier";
import Status from "../../utils/Status";
import Button from "../base/button/Button";
import './ChangeList.scss'

interface IChangeListProps {
  list: Array<App.ChangeList.IChangeListRowState>
  isEnd: boolean
  busy: boolean
  loadMore: () => void
}

export default class ChangeList extends React.Component<IChangeListProps, any> {

  ref: React.RefObject<any>;

  constructor(props: IChangeListProps) {
    super(props);

    this.ref = React.createRef();
  }

  loadOnScroll() {
    if (
      this.ref.current &&
      this.ref.current.querySelector('.load-more') &&
      (this.ref.current.querySelector('.load-more').getBoundingClientRect().top < window.innerHeight + 200)
    ) {
      this.props.loadMore();
    }
  }


  render() {
    let cells = this.props.list.map((element, index) => {
      if (element.type === 'create') {
        let text;
        let link;

        if (localStorage.getItem('role') === 'user') {
          text = `${Modifier.formatTimestamp(element.event_time)} Вы создали `;
          link = <a href={Modifier.parcelHashToUrl(element.hash)}>заявку</a>;
        } else if (localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'super_admin') {
          text = `${Modifier.formatTimestamp(element.event_time)} создана `;
          link = <a href={Modifier.parcelHashToUrl(element.hash)}>заявка</a>;
        }

        return <div key={index} className='change-list-row'>
          {text}
          {link}
          {
            element.user ? ` пользователем ${element.user}` : ''
          }
        </div>;
      } else if (element.type === 'status_change') {
        let text;

        if (localStorage.getItem('role') === 'user')
          text = ` изменила статус на ${element.new_status ? Status.getName(element.new_status) : '*неизвестно*'}`;
        else if (localStorage.getItem('role') === 'admin')
          text = ` изменила статус на ${element.new_status ? Status.getName(element.new_status) : '*неизвестно*'}`;
        else if (localStorage.getItem('role') === 'super_admin')
          text = ` изменила статус на ${element.new_status ? Status.getName(element.new_status) : '*неизвестно*'} пользователем ${element.user || '*неизвестно*'}`;

        return <div key={index} className='change-list-row'>
          {
            `${Modifier.formatTimestamp(element.event_time)} `
          }
          <a href={Modifier.parcelHashToUrl(element.hash)}>{`посылка от ${element.request_create_time ? Modifier.formatTimestamp(element.request_create_time) : '*неизвестно*'} `}</a>
          {text}
        </div>;
      } else if (element.type === 'request_location_add') {
        let text;

        if (localStorage.getItem('role') === 'user')
          text = ` изменила местоположение на ${element.location_name || '*неизвестно*'}`;
        else if (localStorage.getItem('role') === 'admin')
          text = ` изменила местоположение на ${element.location_name || '*неизвестно*'}`;
        else if (localStorage.getItem('role') === 'super_admin')
          text = ` изменила местоположение на ${element.location_name || '*неизвестно*'} пользователем ${element.user || '*неизвестно*'}`;

        return <div key={index} className='change-list-row'>
          {
            `${Modifier.formatTimestamp(element.event_time)} `
          }
          <a href={Modifier.parcelHashToUrl(element.hash)}>{`посылка от ${element.request_create_time ? Modifier.formatTimestamp(element.request_create_time) : '*неизвестно*'} `}</a>
          {text}
        </div>;
      } else
        return <></>
    });

    return <div
      ref={this.ref}
      className='change-list'
      onWheel={this.loadOnScroll.bind(this)}
      onScroll={this.loadOnScroll.bind(this)}
    >
      {cells}
      {
        this.props.isEnd || this.props.busy ?
          <></>
          :
          <Button
            className='load-more'
            value='ЗАГРУЗИТЬ ЕЩЁ'
            onClick={() => this.props.loadMore()}
          />
      }
    </div>
  }
}