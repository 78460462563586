import React from "react";
import './Select.scss'

interface ISelectProps {
  name: string
  value: number | string
  options?: Array<App.CustomSelect.IOption>
  onBlur?: (name: string) => void
  onFocus?: (name: string) => void
  onChange: (name: string, value: string | number) => void
}

interface ISelectState {
  opened: boolean
}

export default class Select extends React.Component<ISelectProps, ISelectState>{
  constructor(props: ISelectProps) {
    super(props);

    this.state = {
      opened: false
    }
  }

  onSelect(event: React.MouseEvent<HTMLDivElement>, name: string, value: number | string) {
    event.stopPropagation();
    this.props.onChange(name, value);
    this.setState({
      opened: false
    });
    this.props.onBlur?.(name);
  }

  onClick(event: React.MouseEvent<HTMLDivElement>, name: string) {
    event.stopPropagation();
    if (this.state.opened && this.props.onBlur)
      this.props.onBlur(name);
    else if (!this.state.opened && this.props.onFocus)
      this.props.onFocus(name);
    this.setState((state) => {
      return {
        opened: !state.opened
      }
    })
  }

  render() {
    let option = this.props.options?.find(element => element.id === this.props.value);
    let selectedOption = option ? option.label : '';

    return <div
      className={`custom-select ${this.state.opened ? 'opened' : ''}`}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => this.onClick(event, this.props.name)}
    >
      <div className='select'>
        {
          this.state.opened ?
            <div className='select-detailed-view'>
              {
                (this.props.options && this.props.options.length) ?
                this.props.options.map((element, index) => {
                  let className = 'option';
                  if (element.selected)
                    className += ' selected';
                  else if (element.disabled)
                    className += ' disabled';

                  return <div
                      key={index}
                      className={className}
                      onClick={(event: React.MouseEvent<HTMLDivElement>) => ((element.selected || element.disabled) ? this.onClick(event, this.props.name) : this.onSelect(event, this.props.name, element.id))}
                    >
                      {element.label}
                    </div>
                })
                  :
                  <div
                    className='no-option'
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => this.onClick(event, this.props.name)}
                  >
                    Нет доступных опций
                  </div>
              }
            </div>
            :
            <div
              className='select-short-view'
              onClick={(event: React.MouseEvent<HTMLDivElement>) => this.onClick(event, this.props.name)}
            >
              {selectedOption}
            </div>
        }
      </div>
      <div
        className='chevron'
        onClick={(event: React.MouseEvent<HTMLDivElement>) => this.onClick(event, this.props.name)}
      />
    </div>
  }
}