import React from "react";
import InQueue from '../assets/statusbar/in_queue.svg'
import InProcess from '../assets/statusbar/in_process.svg'
import Completed from '../assets/statusbar/completed.svg'
import Refused from '../assets/statusbar/refused.svg'

export default class Status {
  static statusImageMap: App.IObject = {
    in_queue: InQueue,
    in_process: InProcess,
    completed: Completed,
    refused: Refused,
    'Новый заказ': InQueue,
    'В работе': InProcess,
    'Завершен': Completed,
    'Отклонен': Refused,
  };

  static statusNameMap: App.IObject = {
    in_queue: 'Новый заказ',
    in_process: 'В работе',
    completed: 'Завершен',
    refused: 'Отклонен',
  };

  static getHtml(status: string): JSX.Element {
    return Status.statusImageMap.hasOwnProperty(status) ?
      <img className='status-img' src={Status.statusImageMap[status]} alt={Status.statusNameMap[status]}/>
      :
      <></>
  }

  static getName(status: string): string {
    return Status.statusNameMap.hasOwnProperty(status) ? Status.statusNameMap[status] : '';
  }
}