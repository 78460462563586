import React from "react";
import MainInfo from "../mainInfo/MainInfo";
import QRCode from 'qrcode'
import routes from "../../views/routes";
import {baseUrl} from "../../utils/Config"

interface ISendListProps {
  data: App.IObject
}

export default class SendList extends React.Component<ISendListProps, any>{
  render() {
    let img;
    let url = `${baseUrl}${routes.parcel}/${this.props.data['request_hash']}`;
    QRCode.toDataURL(url, {type: 'image/jpeg', width: 192}, function(err, url) {
      if (err)
        console.log(err);

      img = <img
          src={url}
          className='qr-code-image'
          alt='QR code'
          style={{
            flexShrink: 0,
            maxWidth: '100%',
            margin: '10px 0',
          }}
      />;
    });

    let mainInfoStyle : React.CSSProperties = {
      height: '80%',
      maxWidth: '650px',
    };
    let mainInfoRowStyle : React.CSSProperties = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    };
    let labelStyle : React.CSSProperties = {
      fontWeight: 700,
      overflow: 'visible',
      textOverflow: 'unset',
      whiteSpace: 'unset',
      overflowWrap: 'break-word',
    };
    let valueStyle : React.CSSProperties = {
      overflow: 'visible',
      textOverflow: 'unset',
      whiteSpace: 'unset',
      overflowWrap: 'break-word',
    };
    let qrCodeContainerStyle: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '20px',
      maxWidth: '192px',
      fontSize: '.75em',
    };
    let divStyle : React.CSSProperties = {
      flexShrink: 0,
      wordWrap: 'break-word',
      marginLeft: '13px',
      maxWidth: '166px',
      boxSizing: 'border-box',
    };

    return <div className='print-container'>
      <div className='send-list-container' style={{
        padding: '10% 0',
        borderBottom: '2px dashed black',
        height: '34%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}>
        <MainInfo
            data={this.props.data}
            mainInfoStyle={mainInfoStyle}
            mainInfoRowStyle={mainInfoRowStyle}
            labelStyle={labelStyle}
            valueStyle={valueStyle}
            sendList={true}
        />
        {
          img ? <div className='qr-code-container' style={qrCodeContainerStyle}>
              <div style={divStyle}>QR-код страницы с посылкой</div>
                {img}
              <div style={divStyle}>Включите и наведите камеру телефона для считывания</div>
            </div>
            :
            <></>
        }
      </div>
      <div className='send-list-container' style={{
        padding: '10% 0',
        height: '34%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}>
        <MainInfo
            data={this.props.data}
            mainInfoStyle={mainInfoStyle}
            mainInfoRowStyle={mainInfoRowStyle}
            labelStyle={labelStyle}
            valueStyle={valueStyle}
            sendList={true}
        />
        {
          img ? <div className='qr-code-container' style={qrCodeContainerStyle}>
              <div style={divStyle}>QR-код страницы с посылкой</div>
              {img}
              <div style={divStyle}>Включите и наведите камеру телефона для считывания</div>
            </div>
            :
            <></>
        }
      </div>
    </div>
  }
}