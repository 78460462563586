import React from "react";
import ParcelService from "../../../services/ParcelService";
import LocationService from "../../../services/LocationService";
import Modifier from "../../../utils/Modifier";
import Status from "../../../utils/Status";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import ParcelFormContainer from "../form/ParcelFormContainer";
import StatusEditTool from "../../../components/statusEditTool/StatusEditTool";
import MainInfo from "../../../components/mainInfo/MainInfo";
import Button from "../../../components/base/button/Button";
import LocationAddTool from "../../../components/locationAddTool/LocationAddTool";
import LocationHistoryTable from "../../../components/locationHistory/LocationHistoryTable";
import Loader from "../../../components/base/loader/Loader";
import PopUp from "../../../components/popUp/PopUp";
import HelpMessage from "../../../components/helpMessage/HelpMessage";
import ReactDOMServer from 'react-dom/server'
import SendList from "../../../components/sendList/SendList";
import Page from "../../../utils/Page";
import Location from "../../../utils/Location";

interface IParcelPageContainerProps {
  hash: string
  toErrorPage: () => void
}

interface IParcelPageContainerState {
  busy: boolean
  busyLocationEdit: boolean
  loadingLocationHistory: boolean
  popUp?: JSX.Element
  locations: Array<App.Location.ILocationOption>
  helpMessage: {
    refElement?: Element
    refElementRect?: DOMRect
    show: boolean
  }
  data: App.Parcel.IParcelRowState
}

export default class ParcelPageContainer extends React.Component<IParcelPageContainerProps, IParcelPageContainerState> {
  private parcelService: ParcelService;
  private locationService: LocationService;

  constructor(props: IParcelPageContainerProps) {
    super(props);

    this.parcelService = new ParcelService();
    this.locationService = new LocationService();

    this.state = {
      busy: true,
      busyLocationEdit: false,
      loadingLocationHistory: false,
      locations: [],
      data: {
        requestId: 0,
        mainInfo: {
          values: {},
          content: {}
        }
      },
      helpMessage: {
        show: false
      }
    }
  }

  componentDidMount(): void {
    Location.getLocationOptions().then(result => {
      this.setState({
        locations: result
      });
      this.getData().then(result => {
        if (result)
          this.loadParcelLocationHistory(this.state.data.requestId);
      });
    })
  }

  async getData() {
    this.setState({
      busy: true
    });

    let result = await this.parcelService.parcelData(this.props.hash);

    if (result && result.status === 200) {
      Page.setTitle(`Посылка №${result.response['request_id']}`);
      let data = {
        requestId: result.response['request_id'],
        mainInfo: {
          values: {
            request_id: result.response['request_id'],
            from_address: result.response['from_address'],
            sender: result.response['sender'],
            sender_phone: result.response['sender_phone'],
            sender_email: result.response['sender_email'],
            to_address: result.response['to_address'],
            receiver: result.response['receiver'],
            receiver_phone: result.response['receiver_phone'],
            receiver_email: result.response['receiver_email'],
            item: result.response['item'],
            agreed: result.response['agreed'],
            comments: result.response['comments'],
            created_at: result.response['created_at'],
            updated_at: result.response['updated_at'],
            status: result.response['status'],
            location: result.response['current_location_id'],
            request_hash: result.response['request_hash'],
          },
          content: {
            request_id: result.response['request_id'] || 0,
            from_address: this.state.locations.find(e => +e.id === +result.response['from_address'])?.label || '',
            sender: result.response['sender'] || '',
            sender_phone: Modifier.phoneValueToMask(String(result.response['sender_phone'])) || '',
            sender_email: result.response['sender_email'] || '',
            to_address: this.state.locations.find(e => +e.id === +result.response['to_address'])?.label || '',
            receiver: result.response['receiver'] || '',
            receiver_phone: Modifier.phoneValueToMask(String(result.response['receiver_phone'])) || '',
            receiver_email: result.response['receiver_email'] || '',
            item: result.response['item'] || '',
            agreed: result.response['agreed'] || '',
            comments: result.response['comments'] || '',
            created_at: Modifier.formatTimestamp(Number(result.response['created_at'])) || '',
            updated_at: Modifier.formatTimestamp(Number(result.response['updated_at'])) || '',
            status: Status.getName(result.response['status']) || '',
            location: this.state.locations.find(e => +e.id === +result.response['current_location_id'])?.label || '',
            request_hash: result.response['request_hash'] || ''
          }
        },
        locationHistory: undefined
      };

      this.setState({
        data: data,
        busy: false
      });
      return true;
    } else {
      this.setState({
        busy: false
      });
      this.props.toErrorPage();
      return false;
    }
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  async sendParcelNewStatus(requestId: number) {
    let result = await this.parcelService.editStatus(requestId, this.state.data.mainInfo.values.status);
    if (result && result.status === 200) {
      if (result.response['status'] && result.response['updated_at'])
        this.changeParcelMainInfo(requestId, {
          status: result.response['status'],
          updated_at: result.response['updated_at']
        });
      else
        this.setState({
          popUp: <ErrorPopUp />
        })
    } else if (result)
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
    else
      this.setState({
        popUp: <ErrorPopUp />
      });

    return true;
  }

  changeParcelMainInfo(requestId: number, data: App.IObject) {
    this.setState((oldState: IParcelPageContainerState) => {
      let newData = JSON.parse(JSON.stringify(oldState.data));
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          newData.mainInfo.values[key] = data[key];
          if (['from_address', 'to_address', 'location'].includes(key))
            newData.mainInfo.content[key] = this.state.locations.find(e => +e.id === +data[key])?.label || '';
          else if (['sender_phone', 'receiver_phone'].includes(key))
            newData.mainInfo.content[key] = Modifier.phoneValueToMask(String(data[key]));
          else if ('status' === key)
            newData.mainInfo.content[key] = Status.getName(data['status']) || '';
          else if (['created_at', 'updated_at'].includes(key))
            newData.mainInfo.content[key] = Modifier.formatTimestamp(data[key]) || '';
          else
            newData.mainInfo.content[key] = data[key];
        }
      }

      return {
        data: newData
      };
    })
  }

  changeParcelStatus(requestId: number, status: string) {
    this.changeParcelMainInfo(requestId, {
      status: status
    });
  }

  async loadParcelLocationHistory(requestId: number) {
    let result = await this.parcelService.getParcelLocationHistory(requestId);
    this.handleLocationHistoryLoadResult(requestId, result);
  }

  async addRecordToParcelLocationHistory(requestId: number, locationId: number) {
    if (!locationId) return;
    let result = await this.parcelService.addToLocationHistory(requestId, locationId);
    this.handleLocationHistoryLoadResult(requestId, result);
    return true;
  }

  async deleteRecordFromParcelLocationHistory(requestLocationId: number, requestId: number, locationId: number) {
    let result = await this.parcelService.deleteFromLocationHistory(requestLocationId, requestId, locationId);
    this.handleLocationHistoryLoadResult(requestId, result);
    return true;
  }

  handleLocationHistoryLoadResult(requestId: number, result: any) {
    if (result && result.status === 200) {
      if (Array.isArray(result.response))
        this.editParcelLocationHistory(requestId, result.response.length ? result.response : undefined);
      else
        this.setState({
          popUp: <ErrorPopUp />
        })
    } else if (result)
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
    else
      this.setState({
        popUp: <ErrorPopUp />
      });
  }

  editParcelLocationHistory(requestId: number, locationHistory?: Array<App.Location.ILocationHistory>) {
    this.setState((oldState: IParcelPageContainerState) => {
      let newData = JSON.parse(JSON.stringify(oldState.data));
      newData.locationHistory = locationHistory;
      return {
        data: newData
      };
    });
    this.changeParcelMainInfo(requestId, {
      location: locationHistory?.[0].location_id
    })
  }

  defineHelpMessageElement(element: Element, rect: DOMRect) {
    this.setState({
      helpMessage: {
        show: true,
        refElement: element,
        refElementRect: rect
      }
    });
  }

  hideHelpMessageElement() {
    if (this.state.helpMessage)
      this.setState(oldState => ({
        helpMessage: {
          ...oldState.helpMessage,
          show: false
        }
      }));
  }

  openEditForm() {
    let popUp = <ParcelFormContainer
      edit={true}
      initialValues={this.state.data.mainInfo.values}
      responseCallback={(data: any) => this.changeParcelMainInfo(data['request_id'], data)}
      destroyElement={this.closePopUp.bind(this)}
    />;

    this.setState({
      popUp: popUp
    })
  }

  printSendList() {
    let winPrint = window.open('', '', 'width=1000,height=1000');
    if (winPrint) {
      let element = <SendList
        data={this.state.data.mainInfo.content}
      />;
      let print = ReactDOMServer.renderToStaticMarkup(element);

      winPrint.document.write(`<html>
        <head>
            <title>Печать</title>
        </head>
      <body onload="setTimeout(window.print, 500);setTimeout(window.close, 500)">`);
      winPrint.document.write(print);
      winPrint.document.write('</body></html>');

      // let styles = document.querySelectorAll('style');
      // styles.forEach(style => {
      //   let newStyle = document.createElement('style');
      //   newStyle.type = "text/css";
      //   newStyle.innerText = style.innerText;
      //   if (winPrint)
      //     winPrint.document.head.appendChild(newStyle);
      // });

      winPrint.document.close();
      winPrint.focus();
    }
  }

  render() {
    let requestId = this.state.data.mainInfo.values['request_id'];
    let editAllowed = ['admin', 'super_admin'].includes(localStorage.getItem('role') || '');
    return this.state.busy ?
      <div className='parcel-page-container'>
        <div className='parcel-page-loader'><Loader /></div>
      </div>
      :
      <div className='parcel-page-container'>
        <div className='page-title-send-list-container'>
          <div className='page-title'>Информация о посылке</div>
          <div className='print-send-list' onClick={this.printSendList.bind(this)}>Печатать отправочный лист</div>
        </div>
        <div className='status-bar-main-info-container'>
          {
            editAllowed ?
              <StatusEditTool
                requestId={requestId}
                value={this.state.data.mainInfo.values['status']}
                changeStatus={this.changeParcelStatus.bind(this)}
                acceptChanges={this.sendParcelNewStatus.bind(this)}
              />
              :
              <></>
          }
          <MainInfo
            data={this.state.data.mainInfo.content}
            helpMessage={{
              define: this.defineHelpMessageElement.bind(this),
              hide: this.hideHelpMessageElement.bind(this)
            }}
          />
          {
            editAllowed ?
              <div className='edit-button-container'>
                <Button
                  value='Редактировать'
                  onClick={this.openEditForm.bind(this)}
                />
              </div>
              :
              <></>
          }
        </div>
        <div className='location-history-container'>
          <div className='location-history-title'>История передвижения посылки</div>
          {
            editAllowed ?
              <LocationAddTool
                requestId={requestId}
                locationList={this.state.locations}
                busy={this.state.busyLocationEdit}
                add={this.addRecordToParcelLocationHistory.bind(this)}
              />
              :
              <></>
          }
          <LocationHistoryTable
            requestId={requestId}
            data={this.state.data.locationHistory}
            loading={this.state.loadingLocationHistory}
            delete={this.deleteRecordFromParcelLocationHistory.bind(this)}
            helpMessage={{
              define: this.defineHelpMessageElement.bind(this),
              hide: this.hideHelpMessageElement.bind(this)
            }}
            deleteAllowed={editAllowed}
          />
        </div>
        {
          this.state.popUp ?
            <PopUp
              children={this.state.popUp}
              callback={this.closePopUp.bind(this)}
            />
            :
            <></>
        }
        <HelpMessage
          text={this.state.helpMessage.refElement?.getAttribute('data-hover')}
          {...this.state.helpMessage}
        />
      </div>
  }
}