import React from "react";
import NotificationControlController from "../../../containers/notification/control/NotificationControlController";
import './NotificationControlView.scss'
import Page from "../../../utils/Page";
import {mode} from "../../../utils/Config";

export default class NotificationControlView extends React.Component<any, any> {
  componentDidMount(): void {
    Page.setTitle('Настройка уведомлений');
    // @ts-ignore
    if (mode === 'prod')
      (async () => {
        await new Promise(() => {
          let interval = setInterval(() => {
            let captcha = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>;
            if (captcha[0]) {
              captcha[0].style.opacity = "0";
              clearInterval(interval);
            }
          }, 1000)
        })
      })()
  }

  render() {
    return <div className='notification-control-view'>
      <NotificationControlController/>
    </div>
  }
}