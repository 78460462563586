import React from "react";
import UserTableRow from "./components/userTableRow/UserTableRow";
import UserTableTitles from "./components/userTableTitles/UserTableTitles";

interface IUserTableProps {
  data: Array<{
    values: App.User.IUserData,
    content: App.User.IUserContent
  }>
  edit: (userId?: number) => void
  helpMessage: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
}

export default class UserTable extends React.Component<IUserTableProps, any>{
  static userTableTitles = {
    'user_id': 'Номер',
    'user_email': 'Почта',
    'user_role': 'Роль',
    'user_status': 'Статус',
  };

  render() {
    return <div className='user-table-container'>
      <UserTableTitles/>
      <div className='user-table'>
        {
          this.props.data.map((data, index) => {
            return <UserTableRow
              key={index}
              data={data.content}
              edit={this.props.edit}
              helpMessage={this.props.helpMessage}
              />
          })
        }
      </div>
    </div>
  }
}