import React from 'react';
import LocationHistoryRow from "./components/LocationHistoryRow";
import Loader from "../base/loader/Loader";
import './LocationHistoryTable.scss'

interface ILocationHistoryTableProps {
  requestId: number
  data?: Array<App.Location.ILocationHistory>
  delete: (requestLocationId: number, requestId: number, locationId: number) => void
  helpMessage: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
  loading: boolean
  deleteAllowed?: boolean
}

export default class LocationHistoryTable extends React.Component<ILocationHistoryTableProps, any>{
  static locationHistoryTableTitles: App.IObject = {
    location_name: 'Название',
    location_address: 'Адрес',
    location_time: 'Время'
  };

  render() {
    let titles = [];
    for (let key in LocationHistoryTable.locationHistoryTableTitles) {
      if (LocationHistoryTable.locationHistoryTableTitles.hasOwnProperty(key)) {
        titles.push((<div key={key} className={key}>{LocationHistoryTable.locationHistoryTableTitles[key]}</div>))
      }
    }
    let content;

    if (this.props.loading)
      content = <div className='loader'>
        <Loader/>
      </div>;
    else if (this.props.data && this.props.data.length)
      content = this.props.data.map((data, index) => {
        return <LocationHistoryRow
          key={index}
          requestId={this.props.requestId}
          data={data}
          onClick={this.props.delete}
          hideHelpMessage={this.props.helpMessage.hide}
          defineHelpMessageElement={this.props.helpMessage.define}
          deleteAllowed={this.props.deleteAllowed}
        />
      });
    else
      content = <div className='no-history'>Список перемещений пуст</div>;

    return <div className={`location-history-table${this.props.deleteAllowed ? '' : ' no-delete'}`}>
      <div className='titles'>{titles}</div>
      <div className='content'>
        {content}
      </div>
    </div>
  }
}