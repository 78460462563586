import React from "react";
import UserTable from "../../UserTable";
import './UserTableTitles.scss'

export default class UserTableTitles extends React.Component<any, any> {
  render() {
    let cells = [];
    for (let key in UserTable.userTableTitles) {
      if (UserTable.userTableTitles.hasOwnProperty(key)) {
        let cell: string;
        // @ts-ignore
        cell = UserTable.userTableTitles[key];
        cells.push((
          <div
            key={key}
            className={key}
          >
            {cell}
          </div>
        ));
      }
    }

    return <div className='user-table-titles'>
      {cells}
    </div>
  }
}