import React from "react";
import InputMask from 'react-input-mask'
import './Input.scss'

interface IInputProps {
  name: string
  value: string | number
  type: string
  valueType: string
  onBlur: (name: string) => void
  onFocus: (name: string) => void
  onChange: (name: string, value: string | number) => void
}

export default class Input extends React.Component<IInputProps, any>{
  render() {
    return this.props.valueType === 'phone' ?
      <InputMask
        value={this.props.value}
        type={this.props.type}
        mask="+375(99)999-99-99"
        maskChar="_"
        onFocus={(event: React.FocusEvent<HTMLInputElement>) => this.props.onFocus(this.props.name)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => this.props.onBlur(this.props.name)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.onChange(this.props.name, event.target.value)}
      />
      :
      <input
        value={this.props.value}
        type={this.props.type}
        onFocus={(event: React.FocusEvent<HTMLInputElement>) => this.props.onFocus(this.props.name)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => this.props.onBlur(this.props.name)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.onChange(this.props.name, event.target.value)}
      />
  }
}