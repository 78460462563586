import React from "react";
import './Button.scss'
import QuestionPopUp from "../../popUp/questionPopUp/QuestionPopUp";

interface IButtonProps {
  value: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  className?: string
  submit?: boolean
  question?: boolean
}

interface IButtonState {
  popUp?: JSX.Element
}

export default class Button extends React.Component<IButtonProps, IButtonState> {
  constructor(props: IButtonProps) {
    super(props);

    this.state = {
      popUp: undefined
    }
  }

  onClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    if (this.props.question) {
      this.setState({
        popUp: <QuestionPopUp
          acceptAction={() => this.props.onClick?.(event)}
          declineAction={this.closePopUp.bind(this)}
        />
      })
    } else
      this.props.onClick?.(event)
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  render() {
    return <div className='button-popup-container'>
      <button
          className={`button ${this.props.className || ''} ${this.props.disabled ? 'disabled' : ''}`}
          type={this.props.submit ? 'submit' : 'button'}
          disabled={this.props.disabled}
          onClick={this.onClick.bind(this)}
        >
          {this.props.value}
        </button>
        {
          this.state.popUp ?
            this.state.popUp
            :
            <></>
        }
    </div>
  }
}