import Network from "../utils/Network";
import Token from "../utils/Token";

export default class UserService {
  private network: Network;

  constructor() {
    this.network = Network.getInstance();
  }

  async role() {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {
      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return this.network.getRequest('get-role', '', headers)
    }
  }

  async list() {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return this.network.getRequest(`users`, '', headers);
    }
  }

  async edit(data: App.IObject, userId: number) {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return this.network.postRequest(`user/edit/${userId}`, data, headers);
    }
  }
}
