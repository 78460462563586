import React from "react";
import LocationService from "../../../services/LocationService";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import LocationTable from "../../../components/table/location/LocationTable";
import PopUp from "../../../components/popUp/PopUp";
import Loader from "../../../components/base/loader/Loader";
import HelpMessage from "../../../components/helpMessage/HelpMessage";
import LocationAddEditFormContainer from "../form/LocationAddEditFormContainer";

interface ILocationTableContainerState {
  busy: boolean
  rows: Array<{
    values: App.Location.ILocationData,
    content: App.Location.ILocationContent
  }>
  popUp?: JSX.Element
  helpMessage: {
    refElement?: Element
    refElementRect?: DOMRect
    show: boolean
  }
  forbidden: boolean
}

export default class LocationTableContainer extends React.Component<any, ILocationTableContainerState>{
  private locationService: LocationService;

  constructor(props: any) {
    super(props);

    this.locationService = new LocationService();

    this.state = {
      busy: false,
      forbidden: false,
      rows: [],
      helpMessage: {
        show: false
      }
    }
  }

  componentDidMount(): void {
    let access = localStorage.getItem('access_token');
    let role = localStorage.getItem('role');
    if (!access || !['super_admin'].includes(role || ''))
      this.setState({
        forbidden: true
      })
    else
      this.getData();
  }

  async getData() {
    if (this.state.busy)
      return;

    this.setState({
      busy: true
    });

    let result = await this.locationService.list();

    if (result && result.status === 200) {
      if (Array.isArray(result.response)) {
        let rows: Array<{
          values: App.Location.ILocationData,
          content: App.Location.ILocationContent
        }> = [];

        result.response.forEach((element: App.IObject) => {
          rows.push({
            values: {
              location_id: element['location_id'],
              location_name: element['location_name'],
              location_address: element['location_address'],
              location_status: element['location_status'],
            },
            content: {
              location_id: element['location_id'] || 0,
              location_name: element['location_name'] || '',
              location_address: element['location_address'] || '',
              location_status: element['location_status'] ? 'Активно' : 'Неактивно',
            }
          })
        });

        this.setState(state =>({
          rows: rows
        }));

      } else
        this.setState({
          popUp: <ErrorPopUp />
        });

    } else if (result && result.status === 403) {
      this.setState({
        forbidden: true
      })
    } else if (result)
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
    else
      this.setState({
        forbidden: true
      })

    this.setState({
      busy: false
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  defineHelpMessageElement(element: Element, rect: DOMRect) {
    this.setState({
      helpMessage: {
        show: true,
        refElement: element,
        refElementRect: rect
      }
    });
  }

  hideHelpMessageElement() {
    if (this.state.helpMessage)
      this.setState(oldState => ({
        helpMessage: {
          ...oldState.helpMessage,
          show: false
        }
      }));
  }

  openAddEditForm(locationId?: number) {
    let popUp = <LocationAddEditFormContainer
      edit={!!locationId}
      initialValues={locationId ? this.state.rows.find(element => +element.values['location_id'] === +locationId)?.values : undefined}
      responseCallback={this.getData.bind(this)}
      destroyElement={this.closePopUp.bind(this)}
    />;

    this.setState({
      popUp: popUp
    })
  }

  render() {
    return this.state.forbidden ?
      <h1 className='forbidden'>Нет доступа</h1>
      :
      <>
        <LocationTable
          data={this.state.rows}
          addEdit={this.openAddEditForm.bind(this)}
          helpMessage={{
            define: this.defineHelpMessageElement.bind(this),
            hide: this.hideHelpMessageElement.bind(this)
          }}
        />
        {
          this.state.popUp ?
            <PopUp
              children={this.state.popUp}
              callback={this.closePopUp.bind(this)}
            />
            :
            <></>
        }
        {
          this.state.busy ?
            <div className='busy-window'>
              <div className='loader'>
                <Loader/>
              </div>
            </div>
            :
            <></>
        }
        <HelpMessage
          text={this.state.helpMessage.refElement?.getAttribute('data-hover')}
          {...this.state.helpMessage}
        />
      </>
  }
}