import {baseUrl} from "./Config";

interface IRequestResult {
  response: any
  status: number
}

export default class Network {
  private static instance: Network;
  private _activeRequests: number = 0;
  // @ts-ignore
  private baseUrl: string = `${baseUrl}/api`;

  private constructor() { }

  public static getInstance(): Network {
    if (!Network.instance) {
      Network.instance = new Network();
    }

    return Network.instance;
  }

  set activeRequests(option) {
    if (option < 0)
      this._activeRequests--;
    if (option > 0)
      this._activeRequests++;

    document.dispatchEvent(new CustomEvent('activeRequestsNumberChange', {detail: this.activeRequests}));
  }

  get activeRequests() {
    return this._activeRequests;
  }

  getRequest(url: string, param: string = '', headers: { [key: string]: string } = {}): Promise<IRequestResult> {
    this.activeRequests = 1;

    let result = new Promise<IRequestResult>((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', param ? `${this.baseUrl}/${url}?${param}` : `${this.baseUrl}/${url}`);

      for (let header in headers) {
        xhr.setRequestHeader(header, headers[header]);
      }

      // xhr.withCredentials = true;
      xhr.send();

      xhr.onload = async () => resolve(await this.onLoad(xhr));
      xhr.onerror = async () => reject(await this.onError());
      xhr.ontimeout = async () => reject(await this.onError());
    }).catch(error => error);

    this.activeRequests = -1;
    return result;
  }

  async postRequest(url: string, body: App.IObject, headers: { [key: string]: string } = {}): Promise<IRequestResult> {
    this.activeRequests = 1;

    let result = await new Promise<IRequestResult>((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', `${this.baseUrl}/${url}`, true);

      xhr.setRequestHeader('Content-Type', 'application/json');
      for (let header in headers) {
        xhr.setRequestHeader(header, headers[header]);
      }

      xhr.send(JSON.stringify(body));

      xhr.onload = async () => resolve(await this.onLoad(xhr));

      xhr.onerror = async () => reject(await this.onError());

      xhr.ontimeout = async () => reject(await this.onError());
    }).catch(error => error);

    this.activeRequests = -1;
    return result;
  }

  async onLoad(xhr: XMLHttpRequest): Promise<IRequestResult> {
    let contentType = xhr.getResponseHeader('content-type');
    if (contentType === 'application/json')
      return {'response': JSON.parse(xhr.response), 'status': xhr.status};
    else
      return {'response': xhr.response, 'status': xhr.status};
  }

  async onError(): Promise<IRequestResult> {
    return {'status': 0, 'response': undefined};
  }
}