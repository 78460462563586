import Network from './Network';

export default class Token {
  static get refreshUrl() {
    return 'auth/refresh';
  }

  static inProcess = false;

  static get access() {
    return (async () => {
      let accessToken = localStorage.getItem('access_token');
      let exp = localStorage.getItem('exp');
      if (accessToken && exp) {
        let currentTimestamp = +(new Date());
        if (+exp < currentTimestamp) {
          if (!Token.inProcess) {
            let refreshResult = await Token.refreshTokens();
            if (!refreshResult) return false;
          } else {
            return await new Promise((resolve) => {
              let interval = setInterval(() => {
                let accessToken = localStorage.getItem('access_token');
                let exp = localStorage.getItem('exp');
                if (accessToken && exp) {
                  let currentTimestamp = +(new Date());
                  if (+exp > currentTimestamp) {
                    clearInterval(interval);
                    resolve(accessToken);
                  }
                }
              }, 1000)
            })
          }
        }
        return localStorage.getItem('access_token');
      } else
        return false;
    })();
  }

  static async refreshTokens() {
    if (Token.inProcess) return;
    Token.inProcess = true;
    let url = Token.refreshUrl;
    let result = await (Network.getInstance()).postRequest(url, {'token': localStorage.getItem('refresh_token')});

    if (result.status === 200) {
      localStorage.setItem('access_token', result.response['access']);
      localStorage.setItem('refresh_token', result.response['refresh']);
      localStorage.setItem('session', result.response['session_id']);

      let payload = JSON.parse(atob(result.response['access'].split('.')[1]));

      let expIn = payload['exp'];
      let role = payload['role'];
      let email = payload['email'];

      localStorage.setItem('exp', expIn);
      localStorage.setItem('role', role);
      localStorage.setItem('email', email);

      Token.inProcess = false;
      return true;
    } else {
      localStorage.clear();
      Token.inProcess = false;
      return false;
    }
  }
}