import React from "react";
import './MainInfo.scss'

interface IMainInfoProps {
  data: App.IObject
  helpMessage?: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
  mainInfoStyle?: React.CSSProperties
  mainInfoRowStyle?: React.CSSProperties
  labelStyle?: React.CSSProperties
  valueStyle?: React.CSSProperties
  sendList?: boolean
}

export default class MainInfo extends React.Component<IMainInfoProps, any> {
  ref: App.IObject;
  constructor(props: IMainInfoProps) {
    super(props);

    this.ref = {};
  }

  static labels: App.IObject = {
    request_id: 'Номер посылки:',
    from_address: 'Точка отправки:',
    sender: 'Отправитель:',
    sender_phone: 'Телефон отправителя:',
    sender_email: 'Почта отправителя:',
    to_address: 'Точка получения:',
    receiver: 'Получатель:',
    receiver_phone: 'Телефон получателя:',
    receiver_email: 'Почта получателя:',
    item: 'Описание посылки:',
    agreed: 'Согласовано с:',
    comments: 'Комментарий:',
    created_at: 'Время формирования заявки:',
    updated_at: 'Время обновления заявки:',
    location: 'Местоположение:',
    status: 'Статус:',
  };


  render() {
    let rows: Array<JSX.Element> = [];
    for (let key in this.props.data) {
      if (this.props.data.hasOwnProperty(key) && MainInfo.labels.hasOwnProperty(key)) {
        this.ref[key] = React.createRef();
        let styles = this.props.mainInfoRowStyle;
        if (this.props.mainInfoRowStyle) {
          styles = { ...this.props.mainInfoRowStyle };
          if (key === 'from_address' || key === 'to_address') {
            styles["borderTop"] = "1px solid black";
            styles["borderLeft"] = "1px solid black";
            styles["borderRight"] = "1px solid black";
          }
          if (key === 'sender' || key === 'receiver') {
            styles["borderBottom"] = "1px solid black";
            styles["borderLeft"] = "1px solid black";
            styles["borderRight"] = "1px solid black";
          }
        }
        rows.push(<div
          ref={this.ref[key]}
          key={key}
          data-hover={this.props.data[key] || ''}
          onMouseOver={() => this.props.helpMessage?.define(this.ref[key].current, this.ref[key].current.getBoundingClientRect())}
          onMouseOut={this.props.helpMessage?.hide}
          style={styles}
        >
          <div className='label' style={this.props.labelStyle}>{MainInfo.labels[key] || ''}</div>
          <div className='value' style={this.props.valueStyle}>{this.props.data[key].name || this.props.data[key] || ''}</div>
        </div>)
      }
    }

    return <div className='main-info' style={this.props.mainInfoStyle}>{rows}</div>
  }
}