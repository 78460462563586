import React from 'react';
import NotificationUserTableRow from "./components/notificationUserTableRow/NotificationUserTableRow";
import './NotificationUserTable.scss'

interface INotificationTableProps {
  locationId: number
  users?: Array<App.Notification.INotificationUserContent>
  delete: (locationUserId: number, locationId: number, userId: number) => void
}

export default class NotificationUserTable extends React.Component<INotificationTableProps, any> {
  render() {
    return <div className='notification-user-table'>
      {
        this.props.users ?
          this.props.users.map((element, index) => {
            return <NotificationUserTableRow
              key={index}
              locationId={this.props.locationId}
              user={element}
              onClick={this.props.delete}
            />
          })
          :
          <div className='no-users'>Список пользователей пуст</div>
      }
    </div>
  }
}