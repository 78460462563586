import React from "react";
import ParcelRow from "./components/parcelTableRow/ParcelRow";
import ParcelTableTitles from "./components/parcelTableTitles/ParcelTableTitles";
import Button from "../../base/button/Button";
import './ParcelTable.scss'

interface IParcelTableProps {
  data: Array<App.Parcel.IParcelRowState>
  isEnd: boolean
  busy: boolean
  actions: {
    mainInfo: {
      edit: (requestId: number) => void
    }
    status: {
      changeStatus: (requestId: number, status: string) => void
      acceptChanges: (requestId: number) => Promise<any>
    }
    locationHistory: {
      add: (requestId: number, locationId: number) => Promise<any>
      delete: (requestLocationId: number, requestId: number, locationId: number) => Promise<any>
      load: (requestId: number) => Promise<any>
    }
    helpMessage: {
      define: (element: Element, rect: DOMRect) => void
      hide: () => void
    }
    loadMoreRows: () => void
  }
  sort: {
    column: string
    order: 'ASC' | 'DESC'
    changeSort: (column: string, order: 'ASC' | 'DESC') => void
  }
  locationList: Array<App.Location.ILocationOption>
}

export default class ParcelTable extends React.Component<IParcelTableProps, any> {
  static parcelTableTitles = {
    'request_id': 'Номер посылки',
    'from_address': 'Точка отправки',
    'sender': 'Отправитель',
    'to_address': 'Точка получения',
    'receiver': 'Получатель',
    'item': 'Описание посылки',
    'agreed': 'Согласовано с',
    'location': 'Местоположение',
    'status': 'Статус'
  };

  static parcelTableSortColumns = ['request_id', 'from_address', 'sender', 'to_address', 'receiver', 'item', 'location'];

  ref: React.RefObject<any>;

  constructor(props: IParcelTableProps) {
    super(props);

    this.ref = React.createRef();
  }

  loadOnScroll() {
    if (
      this.ref.current &&
      this.ref.current.querySelector('.load-more') &&
      (this.ref.current.querySelector('.load-more').getBoundingClientRect().top < window.innerHeight + 200)
    ) {
        this.props.actions.loadMoreRows();
    }
  }

  render() {
    return <div className='parcel-table-container'>
      <ParcelTableTitles
        sort={this.props.sort}
      />
      <div
        ref={this.ref}
        className='parcel-table'
        onWheel={this.loadOnScroll.bind(this)}
        onScroll={this.loadOnScroll.bind(this)}
      >
        {
          this.props.data.length ?
            this.props.data.map((data, index) => {
              return <ParcelRow
                key={index}
                requestId={data.requestId}
                locationList={this.props.locationList}
                shortInfo={data.mainInfo.content}
                mainInfo={{
                  data: data.mainInfo.content,
                  edit: this.props.actions.mainInfo.edit
                }}
                status={{
                  value: data.mainInfo.values.status,
                  changeStatus: this.props.actions.status.changeStatus,
                  acceptChanges: this.props.actions.status.acceptChanges
                }}
                locationHistory={{
                  data: data.locationHistory,
                  add: this.props.actions.locationHistory.add,
                  delete: this.props.actions.locationHistory.delete,
                  load: this.props.actions.locationHistory.load
                }}
                helpMessage={this.props.actions.helpMessage}
              />
            })
            :
            <h1>По заданному запросу ничего не найдено</h1>
        }
        {
          this.props.isEnd || this.props.busy ?
            <></>
              :
            <Button
              className='load-more'
              value='ЗАГРУЗИТЬ ЕЩЁ'
              onClick={() => this.props.actions.loadMoreRows()}
            />
        }
      </div>
    </div>
  }
}