import React from 'react'
import './PopUp.scss'

interface IPopUpProps {
  callback?: () => void
}

export default class PopUp extends React.Component<IPopUpProps, any> {
  onClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.props.callback?.();
  }

  render() {
    return <div
      className='popup'
      onClick={this.onClick.bind(this)}
    >
      <div
        className='popup-content'
        onClick={(event => event.stopPropagation())}
      >
        {this.props.children}
      </div>
    </div>
  }
}