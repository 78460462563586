const routes: App.IObject = {
  main: '/main',
  parcels: '/parcels',
  parcel: '/parcel',
  oldParcel: '/statistic/order/:hash',
  locations: '/locations',
  myParcels: '/my-parcels',
  signIn: '/auth/sign-in',
  signUp: '/auth/sign-up',
  confirm: '/auth/confirm',
  restore: '/auth/restore',
  error: '/error/404',
  users: '/users',
  notifications: '/notifications',
  changeList: '/change-list'
};

export default  routes;