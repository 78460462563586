import React from "react";
import Validator from "../../../utils/Validator";
import CustomInput from "../../base/customInput/CustomInput";
import Loader from "../../base/loader/Loader";
import Button from "../../base/button/Button";
import QuestionPopUp from "../../popUp/questionPopUp/QuestionPopUp";
import '../Form.scss'

interface ILocationAddEditFormProps {
  initialValues?: App.Location.ILocationData
  button: string
  legend: string
  busy: boolean
  onSubmit: (data: App.IObject) => void
}

interface ILocationAddEditFormState {
  inputs: {
    [key: string]: App.Form.IInputData
  }
  popUp?: JSX.Element
}

export default class LocationAddEditForm extends React.Component<ILocationAddEditFormProps, ILocationAddEditFormState>{
  constructor(props: ILocationAddEditFormProps) {
    super(props);

    let options: Array<App.CustomSelect.IOption> = [
      {
        id: 0,
        label: 'Неактивно',
      },
      {
        id: 1,
        label: 'Активно',
      },
    ];

    this.state = {
      inputs: {
        'location_name': {
          label: 'Название:',
          tip: 'Пожалуйста, введите название',
          type: 'text',
          valueType: 'text',
          required: true,
          value: this.props.initialValues?.location_name || '',
          valid: this.props.initialValues?.location_name ? Validator.validate(this.props.initialValues?.location_name, 'text') : undefined
        },
        'location_address': {
          label: 'Название:',
          tip: 'Пожалуйста, введите адрес',
          type: 'text',
          valueType: 'text',
          required: true,
          value: this.props.initialValues?.location_address || '',
          valid: this.props.initialValues?.location_address ? Validator.validate(this.props.initialValues?.location_address, 'text') : undefined
        },
        'location_status': {
          label: 'Статус:',
          tip: 'Пожалуйста, выберите статус',
          type: 'select',
          valueType: 'select',
          required: true,
          options: options,
          value: this.props.initialValues?.location_status !== undefined ? this.props.initialValues?.location_status : 1,
          valid: this.props.initialValues?.location_status !== undefined ? Validator.validate(this.props.initialValues?.location_status, 'text') : true
        },
      }
    }
  }

  changeFormInputState(name: string, value: string | number) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].value = value;

      if (newStateInputs[name].valueType === 'select') {
        Object.keys(newStateInputs).forEach(key => {
          if (newStateInputs[key].valueType === 'select' && key !== name) {
            newStateInputs[key].options?.forEach((option: App.CustomSelect.IOption) => {
              option.selected = +option.id === +value;
            })
          }
        });
      }

      return {
        inputs: newStateInputs
      };
    });
  }

  removeValidation(name: string) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].valid = undefined;
      return {
        inputs: newStateInputs
      };
    })
  }

  setValidation(name: string) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].valid = this.validateInput(newStateInputs[name].value, newStateInputs[name].valueType, newStateInputs[name].required);
      return {
        inputs: newStateInputs
      };
    });
  }

  showFormState() {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      for (let name in newStateInputs) {
        if (newStateInputs.hasOwnProperty(name))
          newStateInputs[name].valid = this.validateInput(newStateInputs[name].value, newStateInputs[name].valueType, newStateInputs[name].required);
      }
      return {
        inputs: newStateInputs
      };
    });
  }

  validateInput(value: number | string, valueType: string, required: boolean) {
    if (((typeof value !== 'number') && value) || (typeof value === 'number'))
      return Validator.validate(value, valueType);
    else
      return !required;
  }

  getFormStateAndValues() {
    let formState = true;
    let formValues: App.IObject = {};
    for (let key in this.state.inputs) {
      if (this.state.inputs.hasOwnProperty(key)) {
        formState = formState && !!this.state.inputs[key].valid;
        formValues[key] = this.state.inputs[key].value;
      }
    }
    return {
      'state': formState,
      'values': formValues
    };
  }

  submit(event: React.FormEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showFormState();
    setTimeout(() => {
      let formStateAndValues = this.getFormStateAndValues();
      if (formStateAndValues.state) {
        this.setState({
          popUp: <QuestionPopUp
            acceptAction={() => this.props.onSubmit(formStateAndValues.values)}
            declineAction={this.closePopUp.bind(this)}
          />
        })
      }
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  render() {
    return <form
      onSubmit={this.submit.bind(this)}
    >
      <fieldset>
        <legend>
          {this.props.legend}
        </legend>
        {
          Object.keys(this.state.inputs).map((name) => {
            const state = this.state.inputs[name];
            return <CustomInput
              key={name}
              name={name}
              {...state}
              onFocus={this.removeValidation.bind(this)}
              onBlur={this.setValidation.bind(this)}
              onChange={this.changeFormInputState.bind(this)}
            />
          })
        }
      </fieldset>
      {
        this.props.busy ?
          <div className='loader'>
            <Loader/>
          </div>
          :
          <Button
            className='submit'
            submit={true}
            value={this.props.button}
          />
      }
      {
        this.state.popUp ?
          this.state.popUp
          :
          <></>
      }
    </form>
  }
}