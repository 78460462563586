import React from "react";
import Button from "../../base/button/Button";
import ParcelForUserTableTitles from "./components/parcelForUserTableTitles/ParcelForUserTableTitles";
import ParcelForUserTableRow from "./components/parcelForUserTableRow/ParcelForUserTableRow";
import './ParcelForUserTable.scss'

interface IParcelForUserTableProps {
  data: Array<App.Parcel.IParcelRowState>
  isEnd: boolean
  busy: boolean
  loadMoreRows: () => void
  locationList: Array<App.Location.ILocationOption>
  helpMessage: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
}

export default class ParcelForUserTable extends React.Component<IParcelForUserTableProps, any> {
  static parcelForUserTableTitles = {
    'request_id': 'Номер посылки',
    'from_address': 'Точка отправки',
    'sender': 'Отправитель',
    'to_address': 'Точка получения',
    'receiver': 'Получатель',
    'item': 'Описание посылки',
    'agreed': 'Согласовано с',
    'location': 'Местоположение',
    'status': 'Статус'
  };

  ref: React.RefObject<any>;

  constructor(props: IParcelForUserTableProps) {
    super(props);

    this.ref = React.createRef();
  }

  loadOnScroll() {
    if (
      this.ref.current &&
      this.ref.current.querySelector('.load-more') &&
      (this.ref.current.querySelector('.load-more').getBoundingClientRect().top < window.innerHeight + 200)
    ) {
      this.props.loadMoreRows();
    }
  }

  render() {
    return <div className='user-parcel-table-container'>
      <ParcelForUserTableTitles/>
      <div
        ref={this.ref}
        className='user-parcel-table'
        onWheel={this.loadOnScroll.bind(this)}
        onScroll={this.loadOnScroll.bind(this)}
      >
        {
          this.props.data.length ?
            this.props.data.map((data, index) => {
              return <ParcelForUserTableRow
                key={index}
                data={data.mainInfo.content}
                helpMessage={this.props.helpMessage}
              />
            })
            :
            <h1>По заданному запросу ничего не найдено</h1>
        }
        {
          this.props.isEnd || this.props.busy ?
            <></>
            :
            <Button
              className='load-more'
              value='ЗАГРУЗИТЬ ЕЩЁ'
              onClick={() => this.props.loadMoreRows()}
            />
        }
      </div>
    </div>
  }
}