import React from "react";
import Page from "../../utils/Page";
import ChangeListContainer from "../../containers/changeList/ChangeListContainer";
import './ChangeListView.scss'
import {mode} from "../../utils/Config";

export default class NotificationControlView extends React.Component<any, any> {
  componentDidMount(): void {
    Page.setTitle('Список уведомлений');
    // @ts-ignore
    if (mode === 'prod')
      (async () => {
        await new Promise(() => {
          let interval = setInterval(() => {
            let captcha = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>;
            if (captcha[0]) {
              captcha[0].style.opacity = "0";
              clearInterval(interval);
            }
          }, 1000)
        })
      })()
  }

  render() {
    return <div className='change-list-view'>
      <ChangeListContainer/>
    </div>
  }
}