import React from "react";
import LocationService from "../../../services/LocationService";
import PopUp from "../../../components/popUp/PopUp";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import LocationAddEditForm from "../../../components/form/locationAddEdit/LocationAddEditForm";

interface ILocationAddEditFormContainerProps {
  initialValues?: App.Location.ILocationData
  edit?: boolean
  responseCallback?: (data: any) => void
  destroyElement?: () => void
}

interface ILocationAddEditFormContainerState {
  busy: boolean
  popUp?: JSX.Element
}

export default class LocationAddEditFormContainer extends React.Component<ILocationAddEditFormContainerProps, ILocationAddEditFormContainerState> {
  private locationService: LocationService;
  constructor(props: ILocationAddEditFormContainerProps) {
    super(props);

    this.locationService = new LocationService();

    this.state = {
      busy: false,
    }
  }

  sendData(data: App.IObject) {
    this.setState({
      busy: true
    });

    (
      this.props.edit ?
        this.locationService.edit(data, this.props.initialValues?.['location_id'] || 0)
        :
        this.locationService.new(data)).then((result
    ) => {
      let popUp;

      if (result && result.status === 200)
        popUp = <h1>
          {
            this.props.edit ?
              'Изменения записаны'
              :
              'Местоположение добавлено'
          }
        </h1>;
      else if (result)
        popUp = <ErrorPopUp errors={result.response} />;
      else
        popUp = <ErrorPopUp />;

      this.setState({
        popUp: popUp,
        busy: false
      });

      this.props.responseCallback?.(result ? result.response : null);
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    });

    setTimeout(() => this.props.destroyElement?.());
  }

  render() {
    const legend = this.props.edit ? `Местоположение №${this.props.initialValues?.['location_id']}`
      : 'Добавить местоположение';
    const button = this.props.edit ? 'Сохранить' : 'Добавить';

    return <>
      <LocationAddEditForm
        onSubmit={this.sendData.bind(this)}
        button={button}
        legend={legend}
        busy={this.state.busy}
        initialValues={this.props.initialValues}
      />
      {
        this.state.popUp ?
          <PopUp
            children={this.state.popUp}
            callback={this.closePopUp.bind(this)}
          />
          :
          <></>
      }
    </>
  }
}