import React from "react";
import ParcelForm from "../../../components/form/parcel/ParcelForm";
import ParcelService from "../../../services/ParcelService";
import PopUp from "../../../components/popUp/PopUp";
import Button from "../../../components/base/button/Button";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import Location from "../../../utils/Location";

interface IParcelFormContainerProps {
  initialValues?: App.IObject
  edit?: boolean
  toParcelPage?: (hash: string) => void
  responseCallback?: (data: any) => void
  destroyElement?: () => void
}

interface IParcelFormContainerState {
  locations: Array<App.Location.ILocationOption>
  busy: boolean
  popUp?: JSX.Element
}

export default class ParcelFormContainer extends React.Component<IParcelFormContainerProps, IParcelFormContainerState> {
  private parcelService: ParcelService;
  constructor(props: IParcelFormContainerProps) {
    super(props);

    this.parcelService = new ParcelService();

    this.state = {
      locations: [],
      busy: false,
    }
  }

  componentDidMount(): void {
    Location.getLocationOptions().then(result => {
      this.setState({
        locations: result
      })
    })
  }

  sendData(data: App.IObject) {
    this.setState({
      busy: true
    });

    (
      this.props.edit ?
        this.parcelService.edit(data, this.props.initialValues?.['request_id'])
        :
        this.parcelService.new(data)
    ).then((result) => {
        let popUp;

        if (result && result.status === 200) {
          if (this.props.edit) {
            popUp = <h1>Изменения записаны</h1>;
          } else {
            let receiverPart = result.response['receiver_email'] ?
              <span>
                Копия письма была отправлена на указанный Вами адрес электронной почты получателя (
                <span className='normal'>{result.response['receiver_email']}</span>
                ).
              </span>
              :
              <></>;

            popUp = <div className='new-parcel-response-container'>
              <div>
                <h5>Заявка оформлена.</h5>
                Письмо с информацией о заявке отправлено на указанный Вами адрес электронной почты (
                <span className='normal'>{result.response['sender_email'] || ''}</span>)
                .&nbsp;
                <br/>
                {receiverPart}
                <br/>
                Отследить <span className='normal'>статус заявки</span> можно перейдя по <span className='normal'>ссылке из письма</span>,
                либо отсканировав <span className='normal'>QR-код</span>, который находится на отправочном листе.
                <br/>
                Отправочный лист Вы можете <span className='normal'>распечатать</span> перейдя на страницу посылки.
              </div>
              <Button
                value='Открыть страницу посылки'
                className='new-parcel-response-button'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  this.props.toParcelPage?.(result.response['hash'] || '');
                }}/>
            </div>
          }
        } else if (result)
          popUp = <ErrorPopUp errors={result.response} />;
        else
          popUp = <ErrorPopUp />

      this.setState({
        popUp: popUp,
        busy: false
      });

      this.props.responseCallback?.(result ? result.response : null);
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    });
    setTimeout(() => this.props.destroyElement?.());
  }

  render() {
    const legend = this.props.edit ?
      `Посылка №${this.props.initialValues?.['request_id']}`
      :
      'Форма создания заявки:';
    const button = this.props.edit ? 'Сохранить' : 'Оформить';

    return <>
      <ParcelForm
        onSubmit={this.sendData.bind(this)}
        button={button}
        legend={legend}
        busy={this.state.busy}
        locations={this.state.locations}
        initialValues={this.props.initialValues}
      />
      {
        this.state.popUp ?
          <PopUp
            children={this.state.popUp}
            callback={this.closePopUp.bind(this)}
          />
        :
          <></>
      }
    </>
  }
}