import React from "react";
import UserService from "../../../services/UserService";
import PopUp from "../../../components/popUp/PopUp";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import UserEditForm from "../../../components/form/userEdit/UserEditForm";

interface IUserEditFormContainerProps {
  initialValues?: App.User.IUserData
  responseCallback?: (data: any) => void
  destroyElement?: () => void
}

interface IUserEditFormContainerState {
  busy: boolean
  popUp?: JSX.Element
}

export default class UserEditFormContainer extends React.Component<IUserEditFormContainerProps, IUserEditFormContainerState> {
  private userService: UserService;
  constructor(props: IUserEditFormContainerProps) {
    super(props);

    this.userService = new UserService();

    this.state = {
      busy: false,
    }
  }

  sendData(data: App.IObject) {
    this.setState({
      busy: true
    });

    this.userService.edit(data, this.props.initialValues?.['user_id'] || 0).then((result) => {
      let popUp;

      if (result && result.status === 200)
        popUp = <h1>Изменения записаны</h1>;
      else if (result)
        popUp = <ErrorPopUp errors={result.response} />;
      else
        popUp = <ErrorPopUp />;

      this.setState({
        popUp: popUp,
        busy: false
      });

      this.props.responseCallback?.(result ? result.response : null);
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    });

    setTimeout(() => this.props.destroyElement?.());
  }

  render() {
    return <div>
      <UserEditForm
        onSubmit={this.sendData.bind(this)}
        busy={this.state.busy}
        initialValues={this.props.initialValues}
      />
      {
        this.state.popUp ?
          <PopUp
            children={this.state.popUp}
            callback={this.closePopUp.bind(this)}
          />
          :
          <></>
      }
    </div>
  }
}