import React from "react";
import {RouteComponentProps} from "react-router";
import {toParcelPage} from "../../moves";
import Page from "../../../utils/Page";
import ParcelTableForUserContainer from "../../../containers/parcel/tableForUser/ParcelTableForUserContainer";
import './ParcelTableForUserView.scss'
import {mode} from "../../../utils/Config";

export default class ParcelTableForUserView extends React.Component<RouteComponentProps>{
  componentDidMount(): void {
    Page.setTitle('Мои посылки');
    // @ts-ignore
    if (mode === 'prod')
      (async () => {
        await new Promise(() => {
          let interval = setInterval(() => {
            let captcha = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>;
            if (captcha[0]) {
              captcha[0].style.opacity = "0";
              clearInterval(interval);
            }
          }, 1000)
        })
      })()
  }

  render() {
    return <div className='user-parcel-table-view'>
      <ParcelTableForUserContainer
        toParcelPage={(hash: string) => toParcelPage(this.props.history, hash)}
      />
    </div>
  }
}