import React from "react";
import ParcelTable from "../../ParcelTable";
import Chevron from '../../../../../assets/icons/chevron.svg';
import './ParcelTableTitles.scss'

interface IParcelTableTitlesProps {
  sort: {
    column: string
    order: 'ASC' | 'DESC'
    changeSort: (column: string, order: 'ASC' | 'DESC') => void
  }
}

interface IParcelTableTitlesState {
  opened: boolean
}

export default class ParcelTableTitles extends React.Component<IParcelTableTitlesProps, IParcelTableTitlesState> {

  constructor(props: IParcelTableTitlesProps) {
    super(props);

    this.state = {
      opened: false
    }
  }

  changeSort(key: string) {
    let order: 'ASC' | 'DESC';
    if (key === this.props.sort.column) {
      order = this.props.sort.order === 'ASC' ? 'DESC' : 'ASC'
    } else {
      order = 'ASC';
    }
    this.props.sort.changeSort(key, order)
  }

  toggleSortListView() {
    this.setState((oldState: IParcelTableTitlesState) => {
      return {
        opened: !oldState.opened
      }
    })
  }

  render() {
    let titles = [];
    for (let key in ParcelTable.parcelTableTitles) {
      if (ParcelTable.parcelTableTitles.hasOwnProperty(key)) {
        let sortAllowed = ParcelTable.parcelTableSortColumns.includes(key);
        let sort = sortAllowed ? key === this.props.sort.column : undefined;
        titles.push((
          <div
            data-sort-allowed={sortAllowed}
            data-sort={sort}
            data-order={sortAllowed && sort ? this.props.sort.order : undefined}
            key={key}
            className={key}
            onClick={() => sortAllowed ? this.changeSort(key) : {}}
          >
            <div>
              {
                // @ts-ignore
                ParcelTable.parcelTableTitles[key]
              }
            </div>
            <div className='sort-indicator'/>
          </div>));
      }
    }
    let sortAllowed = ParcelTable.parcelTableSortColumns.includes(this.props.sort.column);
    let sort = sortAllowed ? true : undefined;
    let className = 'sort-list-open-button';
    if (this.state.opened)
      className += ' opened';

    return <div className='parcel-table-titles-container'>
      <div
        className={className}
        onClick={this.toggleSortListView.bind(this)}
      >
        <div className='label'>
          <div>Сортировка:</div>
          {
            <div
              data-sort-allowed={sortAllowed}
              data-sort={sort}
              data-order={sortAllowed && sort ? this.props.sort.order : undefined}
              className={this.props.sort.column}
            >
              <div>
                {
                  // @ts-ignore
                  ParcelTable.parcelTableTitles[this.props.sort.column].toLowerCase()
                }
              </div>
              <div className='sort-indicator'/>
            </div>
          }
        </div>
        <img src={Chevron} alt='Развернуть'/>
      </div>
      <div className='parcel-table-titles'>{titles}</div>
    </div>
  }
}