import React from "react";
import {RouteComponentProps} from "react-router";
import AuthNav from "../../components/authNav/AuthNav";
import SignInContainer from "../../containers/auth/signIn/SignInContainer";
import {toErrorPage, toMain, toSignInPage} from "../moves";
import SignUpContainer from "../../containers/auth/signUp/SignUpContainer";
import RestorePasswordContainer from "../../containers/auth/restorePassword/RestorePasswordContainer";
import routes from "../routes";
import './AuthView.scss'
import {mode} from "../../utils/Config";

export default class AuthView extends React.Component<RouteComponentProps>{
  componentDidMount(): void {
    // @ts-ignore
    if (mode === 'prod')
      (async () => {
        await new Promise(() => {
          let interval = setInterval(() => {
            let captcha = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>;
            if (captcha[0]) {
              captcha[0].style.opacity = "1";
              clearInterval(interval);
            }
          }, 1000)
        })
      })()
  }

  getActiveAndHash() {
    let active = this.props.history.location.pathname;

    let hash;
    if (active.indexOf(routes.restore) !== -1) {
      hash = (active.split(routes.restore)[1]).split('/')[1];
    }

    return [active, hash];
  }

  render() {
    let form;
    let [active, hash] = this.getActiveAndHash();
    active = active || '';

    if (active === routes.signIn)
      form = <SignInContainer
        toMainPage={() => toMain(this.props.history)}
      />;
    else if (active === routes.signUp)
      form = <SignUpContainer />;
    else if (active.indexOf(routes.restore) !== -1)
      form = <RestorePasswordContainer
        hash={hash}
        toSignInPage={() => toSignInPage(this.props.history)}
        toErrorPage={() => toErrorPage(this.props.history)}
      />;
    else
      form = <></>;
    return <div className='auth-view'>
      <div className='auth-container'>
        {
          active === routes.signIn || active === routes.signUp ?
            <AuthNav selected={this.props.history.location.pathname}/>
            :
            <></>
        }
        {form}
      </div>
    </div>
  }
}