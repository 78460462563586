import React from "react";
import {RouteComponentProps} from "react-router";
import ParcelPageContainer from "../../../containers/parcel/page/ParcelPageContainer";
import routes from "../../routes";
import {toErrorPage} from "../../moves";
import './ParcelPageView.scss'
import {mode} from "../../../utils/Config";

export default class ParcelPageView extends React.Component<RouteComponentProps>{
  componentDidMount(): void {
    // @ts-ignore
    if (mode === 'prod')
      (async () => {
        await new Promise(() => {
          let interval = setInterval(() => {
            let captcha = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>;
            if (captcha[0]) {
              captcha[0].style.opacity = "0";
              clearInterval(interval);
            }
          }, 1000)
        })
      })()
  }

  getHash() {
    let active = this.props.history.location.pathname;
    let hash;
    if (active.indexOf(routes.parcel) !== -1) {
      hash = (active.split(routes.parcel)[1]).split('/')[1];
    }

    return hash;
  }

  render() {
    let hash = this.getHash();
    if (!hash) {
      toErrorPage(this.props.history);
      return <></>;
    } else
      return <div className='parcel-page-view'>
        <ParcelPageContainer
          hash={hash}
          toErrorPage={() => toErrorPage(this.props.history)}
        />
      </div>
  }
}