import React from "react";
import ParcelTable from "../../../components/table/parcel/ParcelTable";
import ParcelService from "../../../services/ParcelService";
import PopUp from "../../../components/popUp/PopUp";
import Status from "../../../utils/Status";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import Loader from "../../../components/base/loader/Loader";
import SearchTool from "../../../components/searchTool/SearchTool";
import HelpMessage from "../../../components/helpMessage/HelpMessage";
import Modifier from "../../../utils/Modifier";
import ParcelFormContainer from "../form/ParcelFormContainer";
import Location from "../../../utils/Location";

interface IParcelTableContainerProps {
  toParcelPage: (hash: string) => void
}

interface IParcelTableContainerState {
  isEnd: boolean
  page: number
  amount: number
  sort: {
    column: string
    order: 'ASC' | 'DESC'
  }
  busy: boolean
  forbidden: boolean
  rows: Array<App.Parcel.IParcelRowState>
  locations: Array<App.Location.ILocationOption>
  helpMessage: {
    refElement?: Element
    refElementRect?: DOMRect
    show: boolean
  }
  popUp?: JSX.Element
  keyword?: string
}

export default class ParcelTableContainer
  extends React.Component<IParcelTableContainerProps, IParcelTableContainerState> {

  private searchTimeout: NodeJS.Timeout | undefined;
  private parcelService: ParcelService;

  constructor(props: IParcelTableContainerProps) {
    super(props);

    this.parcelService = new ParcelService();

    this.state = {
      isEnd: false,
      page: 0,
      amount: 30,
      busy: false,
      forbidden: false,
      sort: {
        column: 'request_id',
        order: 'DESC'
      },
      rows: [],
      locations: [],
      helpMessage: {
        show: false
      }
    }
  }

  componentDidMount(): void {
    let access = localStorage.getItem('access_token');
    let role = localStorage.getItem('role');
    if (!access || !['admin', 'super_admin'].includes(role || ''))
      this.setState({
        forbidden: true
      })
    else
      Location.getLocationOptions().then(result => {
        this.setState({
          locations: result
        });
        this.getData();
      });
  }

  async getData(clearOld: boolean = false) {
    if (this.state.busy || this.state.isEnd)
      return;

    this.setState({
      busy: true
    });

    let result = await this.parcelService.list({
      page: this.state.page,
      amount: this.state.amount,
      column: this.state.sort.column,
      order: this.state.sort.order,
      keyword: this.state.keyword
    });

    if (result && result.status === 200) {
      if (Array.isArray(result.response)) {
        let isEnd = result.response.length !== this.state.amount;
        let page = this.state.page + 1;
        let rows: Array<App.Parcel.IParcelRowState> = [];

        result.response.forEach((element: App.IObject) => {
          rows.push({
            requestId: element['request_id'],
            mainInfo: {
              values: {
                request_id: element['request_id'],
                from_address: element['from_address'],
                sender: element['sender'],
                sender_phone: element['sender_phone'],
                sender_email: element['sender_email'],
                to_address: element['to_address'],
                receiver: element['receiver'],
                receiver_phone: element['receiver_phone'],
                receiver_email: element['receiver_email'],
                item: element['item'],
                agreed: element['agreed'],
                comments: element['comments'],
                created_at: element['created_at'],
                updated_at: element['updated_at'],
                status: element['status'],
                location: element['current_location_id'],
                request_hash: element['request_hash'],
              },
              content: {
                request_id: element['request_id'] || 0,
                from_address: this.state.locations.find(e => +e.id === +element['from_address'])?.label || '',
                sender: element['sender'] || '',
                sender_phone: Modifier.phoneValueToMask(String(element['sender_phone'])) || '',
                sender_email: element['sender_email'] || '',
                to_address: this.state.locations.find(e => +e.id === +element['to_address'])?.label || '',
                receiver: element['receiver'] || '',
                receiver_phone: Modifier.phoneValueToMask(String(element['receiver_phone'])) || '',
                receiver_email: element['receiver_email'] || '',
                item: element['item'] || '',
                agreed: element['agreed'] || '',
                comments: element['comments'] || '',
                created_at: Modifier.formatTimestamp(Number(element['created_at'])) || '',
                updated_at: Modifier.formatTimestamp(Number(element['updated_at'])) || '',
                status: Status.getName(element['status']) || '',
                location: this.state.locations.find(e => +e.id === +element['current_location_id'])?.label || '',
                request_hash: element['request_hash'] || ''
              }
            },
            locationHistory: undefined
          })
        });

        this.setState(state => ({
          isEnd: isEnd,
          page: page,
          rows: clearOld ? rows : state.rows.concat(rows)
        }));

      } else
        this.setState({
          popUp: <ErrorPopUp />
        });

    } else if (result && result.status === 403) {
      this.setState({
        forbidden: true
      })
    } else if (result)
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
    else
      this.setState({
        forbidden: true
      })

    this.setState({
      busy: false
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  async sendParcelNewStatus(requestId: number) {
    let element = this.state.rows.find(element => element.requestId === requestId);
    if (element) {
      let result = await this.parcelService.editStatus(requestId, element.mainInfo.values.status);
      if (result && result.status === 200) {
        if (result.response['status'] && result.response['updated_at'])
          this.changeParcelMainInfo(requestId, {
            status: result.response['status'],
            updated_at: result.response['updated_at']
          });
        else
          this.setState({
            popUp: <ErrorPopUp />
          })
      } else if (result)
        this.setState({
          popUp: <ErrorPopUp errors={result.response} />
        });
      else
        this.setState({
          popUp: <ErrorPopUp />
        });
    }

    return true;
  }

  changeParcelMainInfo(requestId: number, data: App.IObject) {
    this.setState((oldState: IParcelTableContainerState) => {
      let newStateRows: Array<App.Parcel.IParcelRowState> = JSON.parse(JSON.stringify(oldState.rows));
      let rowIndex = newStateRows.findIndex(element => element.requestId === requestId);
      if (rowIndex !== -1) {
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            newStateRows[rowIndex].mainInfo.values[key] = data[key];
            if (['from_address', 'to_address', 'location'].includes(key))
              newStateRows[rowIndex].mainInfo.content[key] = this.state.locations.find(e => +e.id === +data[key])?.label || '';
            else if (['sender_phone', 'receiver_phone'].includes(key))
              newStateRows[rowIndex].mainInfo.content[key] = Modifier.phoneValueToMask(String(data[key]));
            else if ('status' === key)
              newStateRows[rowIndex].mainInfo.content[key] = Status.getName(data['status']) || '';
            else if (['created_at', 'updated_at'].includes(key))
              newStateRows[rowIndex].mainInfo.content[key] = Modifier.formatTimestamp(data[key]) || '';
            else
              newStateRows[rowIndex].mainInfo.content[key] = data[key];
          }
        }
      }

      return {
        rows: newStateRows
      };
    })
  }

  changeParcelStatus(requestId: number, status: string) {
    this.changeParcelMainInfo(requestId, {
      status: status
    });
  }

  async loadParcelLocationHistory(requestId: number) {
    let result = await this.parcelService.getParcelLocationHistory(requestId);
    this.handleLocationHistoryLoadResult(requestId, result);
  }

  async addRecordToParcelLocationHistory(requestId: number, locationId: number) {
    if (!locationId) return;
    let result = await this.parcelService.addToLocationHistory(requestId, locationId);
    this.handleLocationHistoryLoadResult(requestId, result);
    return true;
  }

  async deleteRecordFromParcelLocationHistory(requestLocationId: number, requestId: number, locationId: number) {
    let result = await this.parcelService.deleteFromLocationHistory(requestLocationId, requestId, locationId);
    this.handleLocationHistoryLoadResult(requestId, result);
    return true;
  }

  handleLocationHistoryLoadResult(requestId: number, result: any) {
    if (result && result.status === 200) {
      if (Array.isArray(result.response))
        this.editParcelLocationHistory(requestId, result.response.length ? result.response : undefined);
      else
        this.setState({
          popUp: <ErrorPopUp />
        })
    } else if (result)
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
    else
      this.setState({
        popUp: <ErrorPopUp />
      });
  }

  editParcelLocationHistory(requestId: number, locationHistory?: Array<App.Location.ILocationHistory>) {
    this.setState((oldState: IParcelTableContainerState) => {
      let newStateRows: Array<App.Parcel.IParcelRowState> = JSON.parse(JSON.stringify(oldState.rows));
      let rowIndex = newStateRows.findIndex((element) => element.requestId === requestId);
      if (rowIndex !== -1) {
        newStateRows[rowIndex].locationHistory = locationHistory;
      }
      return {
        rows: newStateRows
      };
    });
    this.changeParcelMainInfo(requestId, {
      location: locationHistory?.[0].location_id
    })
  }

  changeSort(column: string, order: 'ASC' | 'DESC') {
    this.setState({
      sort: {
        column: column,
        order: order
      },
      isEnd: false,
      page: 0
    });

    setTimeout(() => this.getData(true));
  }

  inputKeyword(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      isEnd: false,
      page: 0,
      keyword: event.target.value
    });

    if (this.searchTimeout)
      clearTimeout(this.searchTimeout)

    this.searchTimeout = setTimeout(() => this.getData(true), 1000);
  }

  defineHelpMessageElement(element: Element, rect: DOMRect) {
    this.setState({
      helpMessage: {
        show: true,
        refElement: element,
        refElementRect: rect
      }
    });
  }

  hideHelpMessageElement() {
    if (this.state.helpMessage)
      this.setState(oldState => ({
        helpMessage: {
          ...oldState.helpMessage,
          show: false
        }
      }));
  }

  openEditForm(requestId: number) {
    let popUp = <ParcelFormContainer
      edit={true}
      initialValues={this.state.rows.find(element => +element.mainInfo.values['request_id'] === +requestId)?.mainInfo.values || {}}
      responseCallback={(data: any) => this.changeParcelMainInfo(data['request_id'], data)}
      destroyElement={this.closePopUp.bind(this)}
    />;

    this.setState({
      popUp: popUp
    })
  }

  render() {
    return this.state.forbidden ?
      <h1 className='forbidden'>Нет доступа</h1>
      :
      <>
        <SearchTool onInput={this.inputKeyword.bind(this)} search={() => {
          this.setState({
            isEnd: false,
            page: 0
          });
          setTimeout(() => this.getData(true));
        }} />
        <ParcelTable
          data={this.state.rows}
          isEnd={this.state.isEnd}
          busy={this.state.busy}
          actions={{
            status: {
              acceptChanges: this.sendParcelNewStatus.bind(this),
              changeStatus: this.changeParcelStatus.bind(this),
            },
            mainInfo: {
              edit: this.openEditForm.bind(this),
            },
            locationHistory: {
              add: this.addRecordToParcelLocationHistory.bind(this),
              delete: this.deleteRecordFromParcelLocationHistory.bind(this),
              load: this.loadParcelLocationHistory.bind(this)
            },
            helpMessage: {
              define: this.defineHelpMessageElement.bind(this),
              hide: this.hideHelpMessageElement.bind(this)
            },
            loadMoreRows: this.getData.bind(this)
          }}
          sort={{
            column: this.state.sort.column,
            order: this.state.sort.order,
            changeSort: this.changeSort.bind(this)
          }}
          locationList={this.state.locations}
        />
        {
          this.state.popUp ?
            <PopUp
              children={this.state.popUp}
              callback={this.closePopUp.bind(this)}
            />
            :
            <></>
        }
        {
          this.state.busy ?
            <div className='busy-window'>
              <div className='loader'>
                <Loader />
              </div>
            </div>
            :
            <></>
        }
        <HelpMessage
          text={this.state.helpMessage.refElement?.getAttribute('data-hover')}
          {...this.state.helpMessage}
        />
      </>
  }
}