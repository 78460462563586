import React from "react";
import Image from '../../../../assets/icons/edit.svg'
import './EditButton.scss'

interface IEditButtonProps {
  onClick: () => void
}

export default class EditButton extends React.Component<IEditButtonProps, any> {
  render() {
    return <div
      className='edit-button'
      onClick={(event) => {
        event.stopPropagation();
        this.props.onClick();
      }}
    >
      <img className='edit-button-img' src={Image} alt='edit'/>
      <div className='edit-button-label'>Редактировать</div>
    </div>
  }
}