import React from 'react';
import './AuthNav.scss'
import routes from "../../views/routes";
import {Link} from "react-router-dom";

interface IAuthNavProps {
  selected: string
}

export default class AuthNav extends React.Component<IAuthNavProps, any>{
  render() {
    return <div className='auth-nav'>
      <Link
        to={routes.signIn}
        className={`sign-in ${this.props.selected === routes.signIn ? 'bold' : ''}`}
      >
        Вход
      </Link>
      <Link
        to={routes.signUp}
        className={`sign-up ${this.props.selected === routes.signUp ? 'bold' : ''}`}
      >
        Регистрация
      </Link>
    </div>
  }
}