import React from "react";
import NotificationListRow from "./components/notificationListRow/NotificationListRow";
import './NotificationList.scss'

interface INotificationListProps {
  data: Array<App.Notification.INotificationListContent>
  select: (locationId: number) => void
}

export default class NotificationList extends React.Component<INotificationListProps, any> {
  render() {
    return <div className='notification-list'>
      {
        this.props.data.map((row, index) => {
          return <NotificationListRow
            key={index}
            location={row.location}
            users={row.users}
            select={this.props.select}
          />
        })
      }
    </div>
  }
}