import React from "react";
import {RouteComponentProps} from "react-router";
import ParcelFormContainer from "../../containers/parcel/form/ParcelFormContainer";
import {toParcelPage} from "../moves";
import Page from "../../utils/Page";
import './MainView.scss';
import {mode} from "../../utils/Config";

export default class MainView extends React.Component<RouteComponentProps> {
  componentDidMount(): void {
    Page.setTitle('Главная');
    // @ts-ignore
    if (mode === 'prod')
      (async () => {
        await new Promise(() => {
          let interval = setInterval(() => {
            let captcha = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>;
            if (captcha[0]) {
              captcha[0].style.opacity = "1";
              clearInterval(interval);
            }
          }, 1000)
        })
      })()
  }

  render() {
    let initialValues;
    let email = localStorage.getItem('email');
    if (email)
      initialValues = {
        sender_email: email
      };
    return <div className='main'>
      <ParcelFormContainer
        toParcelPage={(hash: string) => toParcelPage(this.props.history, hash)}
        initialValues={initialValues}
      />
    </div>
  }
}