import React from "react";
import * as Logo from '../../assets/logo.png';
import Button from "../base/button/Button";
import {Link} from 'react-router-dom'
import routes from '../../views/routes'
import './Header.scss'

interface IHeaderProps {
  role?: string
  active: string
  logout: () => void
  toMain: () => void
  amountOfChanges: number
}

interface IHeaderState {
  opened: boolean
}

export default class Header extends React.Component<IHeaderProps, any> {
  constructor(props: IHeaderProps) {
    super(props);

    this.state = {
      opened: false
    }
  }

  toggleOpened() {
    this.setState((oldState: IHeaderState) => {
      return {
        opened: !oldState.opened
      }
    })
  }

  render() {
    return <div className='header'>
      <div className='logo' onClick={this.props.toMain}>
        <img src={Logo} alt='Logo'/>
      </div>
      <div
        className={`open-menu-button${this.state.opened ? ' opened' : ''}`}
        onClick={this.toggleOpened.bind(this)}
      >
        <div>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
      <div className='menu'>
        <Link
          to={routes.main}
          onClick={this.toggleOpened.bind(this)}
          className={`main-page${this.props.active === 'main' ? ' selected' : ''}`}
        >
          Главная
        </Link>
        {
          this.props.role && ['admin', 'super_admin'].includes(this.props.role) ?
            <Link
              to={routes.parcels}
              onClick={this.toggleOpened.bind(this)}
              className={`parcels-page${['parcels', 'parcel'].includes(this.props.active) ? ' selected' : ''}`}
            >
              Посылки
            </Link> : <></>
        }
        {
          this.props.role && ['user', 'admin', 'super_admin'].includes(this.props.role) ?
            <Link
              to={routes.myParcels}
              onClick={this.toggleOpened.bind(this)}
              className={`my-parcels-page${this.props.active === 'myParcels' ? ' selected' : ''}`}
            >
              Мои отправления
            </Link> : <></>
        }
        {
          this.props.role === 'super_admin' ?
            <Link
              to={routes.locations}
              onClick={this.toggleOpened.bind(this)}
              className={`locations-page${this.props.active === 'locations' ? ' selected' : ''}`}
            >
              Местоположения
            </Link> : <></>
        }
        {
          this.props.role === 'super_admin' ?
            <Link
              to={routes.users}
              onClick={this.toggleOpened.bind(this)}
              className={`users-page${this.props.active === 'users' ? ' selected' : ''}`}
            >
              Пользователи
            </Link> : <></>
        }
        {
          this.props.role === 'super_admin' ?
            <Link
              to={routes.notifications}
              onClick={this.toggleOpened.bind(this)}
              className={`notifications-page${this.props.active === 'notifications' ? ' selected' : ''}`}
            >
              Настройка уведомлений
            </Link> : <></>
        }
        {
          this.props.role && ['user', 'admin', 'super_admin'].includes(this.props.role) ?
            <Link
              to={routes.changeList}
              onClick={this.toggleOpened.bind(this)}
              className={`change-list-page${this.props.active === 'changeList' ? ' selected' : ''}`}
            >
              Список уведомлений
              <span>({this.props.amountOfChanges})</span>
            </Link> : <></>
        }
        {
          this.props.role ?
            <Button
              value='Выход'
              question={true}
              className='logout'
              onClick={() => {
                this.props.logout();
                this.toggleOpened();
              }}
            /> : <></>
        }
        {
          !this.props.role ?
            <Link
              to={routes.signIn}
              onClick={this.toggleOpened.bind(this)}
              className={`auth-page${(['signIn', 'signUp', 'restore'].includes(this.props.active)) ? ' selected' : ''}`}
            >
              Вход
            </Link> : <></>
        }
      </div>
    </div>
  }
}