import React from 'react';
import routes from "../../views/routes";
import './ParcelPageLink.scss'

interface IParcelPageLinkProps {
  hash: string
  alias?: boolean
}

export default class ParcelPageLink extends React.Component<IParcelPageLinkProps, any> {
  render() {
    return <a
      href={`${routes.parcel}/${this.props.hash}`}
      target='_blank'
      rel='noopener noreferrer'
      className='parcel-page-link-container'
    >
      <div className='parcel-page-link-img'/>
      <div className='parcel-page-link-label'>
        {
          this.props.alias ?
            'Подробнее'
            :
            'Открыть страницу посылки'
        }
      </div>
    </a>
  }
}