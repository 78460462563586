import React from "react";
import LocationTable from "../../LocationTable";
import './LocationTableTitles.scss'

export default class LocationTableTitles extends React.Component<any, any> {
  render() {
    let cells = [];
    for (let key in LocationTable.locationTableTitles) {
      if (LocationTable.locationTableTitles.hasOwnProperty(key)) {
        let cell: string;
        // @ts-ignore
        cell = LocationTable.locationTableTitles[key];
        cells.push((
          <div
            key={key}
            className={key}
          >
            {cell}
          </div>
        ));
      }
    }

    return <div className='location-table-titles'>
      {cells}
    </div>
  }
}