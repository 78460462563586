import React from "react";
import PopUp from "../../../components/popUp/PopUp";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import AuthService from "../../../services/AuthService";
import RestorePasswordForm from "../../../components/form/restorePassword/RestorePasswordForm";
import {sha256} from "js-sha256";
import Page from "../../../utils/Page";

interface IRestorePasswordContainerProps {
  toErrorPage: () => void
  toSignInPage: () => void
  hash?: string
}

interface IRestorePasswordContainerState {
  busy: boolean
  step: number
  invalidRestoreToken?: any
  email?: string
  popUp?: JSX.Element
}

export default class RestorePasswordFormContainer extends React.Component<IRestorePasswordContainerProps, IRestorePasswordContainerState> {
  private authService: AuthService;
  constructor(props: IRestorePasswordContainerProps) {
    super(props);

    this.authService = new AuthService();

    Page.setTitle('Восстановление пароля');

    this.state = {
      busy: false,
      step: +(!!this.props.hash)
    }
  }

  componentDidMount(): void {
    if (this.props.hash)
      this.authService.checkRestoreUrl(this.props.hash).then((result) => {
        if (result.status === 200) {
          this.setState({
            email: result.response['email']
          })
        } else {
          this.setState({
            invalidRestoreToken: result.response
          })
        }
      });
  }

  sendData(data: App.IObject) {
    this.setState({
      busy: true
    });

    if (data.hasOwnProperty('password') && data['password']) {
      data['password'] = sha256.create().update(data['password']).hex();
      data['repeat_password'] = sha256.create().update(data['repeat_password']).hex();
    }

    if (this.props.hash)
      data['token'] = this.props.hash;

    (
      this.state.step ?
        this.authService.setNewPassword(data)
        :
        this.authService.restore(data)
    ).then((result) => {
      if (result) {
        this.setState({
          popUp: <ErrorPopUp errors={result?.response} />,
          busy: false
        });
      } else
        this.setState({
          popUp: <ErrorPopUp />,
          busy: false
        });
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    });
    setTimeout(this.props.toSignInPage);
  }

  render() {
    let button = this.state.step ? 'Сохранить' : 'Продолжить';

    return <>
      {
        this.state.invalidRestoreToken ?
          (
            Array.isArray(this.state.invalidRestoreToken) ?
              this.state.invalidRestoreToken.map((element: string) => {
                return <h1 className='invalid-restore-token'>{element}</h1>
              })
              :
              <h1 className='invalid-restore-token'>{this.state.invalidRestoreToken}</h1>
          )
          :
          <RestorePasswordForm
            email={this.state.email}
            button={button}
            step={this.state.step}
            busy={this.state.busy}
            onSubmit={this.sendData.bind(this)}
          />
      }
      {
        this.state.popUp ?
          <PopUp
            children={this.state.popUp}
            callback={this.closePopUp.bind(this)}
          />
          :
          <></>
      }
    </>
  }
}