import React from "react";
import ParcelForUserTable from "../../ParcelForUserTable";
import Status from "../../../../../utils/Status";
import ParcelPageLink from "../../../../parcelPageLink/ParcelPageLink";
import './ParcelForUserTableRow.scss'


interface IParcelForUserTableRowProps {
  data: App.IObject
  helpMessage: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
}

export default class ParcelForUserTableRow extends React.Component<IParcelForUserTableRowProps, any> {
  ref: App.IObject;
  constructor(props: IParcelForUserTableRowProps) {
    super(props);

    this.ref = {};
  }

  render() {
    let cells = [];
    for (let key in ParcelForUserTable.parcelForUserTableTitles) {
      if (this.props.data.hasOwnProperty(key) && ParcelForUserTable.parcelForUserTableTitles.hasOwnProperty(key)) {
        let cell: JSX.Element | string;
        if (key === 'status')
          cell = Status.getHtml(this.props.data[key]);
        else
          cell = this.props.data[key];
        this.ref[key] = React.createRef();
        cells.push((
          <div
            ref={this.ref[key]}
            key={key}
            className={key}
            data-hover={this.props.data[key] || ''}
            onMouseOver={() => this.props.helpMessage.define(this.ref[key].current, this.ref[key].current.getBoundingClientRect())}
            onMouseOut={this.props.helpMessage.hide}
          >
            {cell}
          </div>
        ));
      }
    }
    return <div className='user-parcel-table-row'>
      {
        cells
      }
      <ParcelPageLink hash={this.props.data['request_hash']} alias={true}/>
    </div>
  }
}