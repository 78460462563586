import React from "react";
import ParcelService from "../../../services/ParcelService";
import LocationService from "../../../services/LocationService";
import Modifier from "../../../utils/Modifier";
import Status from "../../../utils/Status";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import SearchTool from "../../../components/searchTool/SearchTool";
import PopUp from "../../../components/popUp/PopUp";
import Loader from "../../../components/base/loader/Loader";
import HelpMessage from "../../../components/helpMessage/HelpMessage";
import ParcelTableForUser from "../../../components/table/parcelForUser/ParcelForUserTable";
import Location from "../../../utils/Location";

interface IParcelTableForUserContainerProps {
  toParcelPage: (hash: string) => void
}

interface IParcelTableForUserContainerState {
  isEnd: boolean
  page: number
  amount: number
  busy: boolean
  forbidden: boolean
  rows: Array<App.Parcel.IParcelRowState>
  popUp?: JSX.Element
  locations: Array<App.Location.ILocationOption>
  keyword?: string
  helpMessage: {
    refElement?: Element
    refElementRect?: DOMRect
    show: boolean
  }
}

export default class ParcelTableForUserContainer
  extends React.Component<IParcelTableForUserContainerProps, IParcelTableForUserContainerState> {

  private searchTimeout: NodeJS.Timeout | undefined;
  private parcelService: ParcelService;
  private locationService: LocationService;

  constructor(props: IParcelTableForUserContainerProps) {
    super(props);

    this.parcelService = new ParcelService();
    this.locationService = new LocationService();

    this.state = {
      isEnd: false,
      page: 0,
      amount: 30,
      busy: false,
      forbidden: false,
      rows: [],
      locations: [],
      helpMessage: {
        show: false
      }
    }
  }

  componentDidMount(): void {
    if (!localStorage.getItem('access_token'))
      this.setState({
        forbidden: true
      })
    else
      Location.getLocationOptions().then(result => {
        this.setState({
          locations: result
        });
        this.getData();
      })
  }

  async getData(clearOld: boolean = false) {
    if (this.state.busy || this.state.isEnd)
      return;

    this.setState({
      busy: true
    });

    let result = await this.parcelService.parcelForUserList({
      page: this.state.page,
      amount: this.state.amount,
      keyword: this.state.keyword
    });

    if (result && result.status === 200) {
      if (Array.isArray(result.response)) {
        let isEnd = result.response.length !== this.state.amount;
        let page = this.state.page + 1;
        let rows: Array<App.Parcel.IParcelRowState> = [];

        result.response.forEach((element: App.IObject) => {
          rows.push({
            requestId: element['request_id'],
            mainInfo: {
              values: {
                request_id: element['request_id'],
                from_address: element['from_address'],
                sender: element['sender'],
                sender_phone: element['sender_phone'],
                sender_email: element['sender_email'],
                to_address: element['to_address'],
                receiver: element['receiver'],
                receiver_phone: element['receiver_phone'],
                receiver_email: element['receiver_email'],
                item: element['item'],
                agreed: element['agreed'],
                comments: element['comments'],
                created_at: element['created_at'],
                updated_at: element['updated_at'],
                status: element['status'],
                location: element['current_location_id'],
                request_hash: element['request_hash'],
              },
              content: {
                request_id: element['request_id'] || 0,
                from_address: this.state.locations.find(e => +e.id === +element['from_address'])?.label || '',
                sender: element['sender'] || '',
                sender_phone: Modifier.phoneValueToMask(String(element['sender_phone'])) || '',
                sender_email: element['sender_email'] || '',
                to_address: this.state.locations.find(e => +e.id === +element['to_address'])?.label || '',
                receiver: element['receiver'] || '',
                receiver_phone: Modifier.phoneValueToMask(String(element['receiver_phone'])) || '',
                receiver_email: element['receiver_email'] || '',
                item: element['item'] || '',
                agreed: element['agreed'] || '',
                comments: element['comments'] || '',
                created_at: Modifier.formatTimestamp(Number(element['created_at'])) || '',
                updated_at: Modifier.formatTimestamp(Number(element['updated_at'])) || '',
                status: Status.getName(element['status']) || '',
                location: this.state.locations.find(e => +e.id === +element['current_location_id'])?.label || '',
                request_hash: element['request_hash'] || ''
              }
            },
          })
        });

        this.setState(state =>({
          isEnd: isEnd,
          page: page,
          rows: clearOld ? rows : state.rows.concat(rows)
        }));

      } else
        this.setState({
          popUp: <ErrorPopUp />
        });

    } else if (result && result.status === 403) {
      this.setState({
        forbidden: true
      })
    } else if (result)
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
    else
      this.setState({
        forbidden: true
      })

    this.setState({
      busy: false
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  inputKeyword(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      isEnd: false,
      page: 0,
      keyword: event.target.value
    });

    if (this.searchTimeout)
      clearTimeout(this.searchTimeout)

    this.searchTimeout = setTimeout(() => this.getData(true), 1000);
  }

  defineHelpMessageElement(element: Element, rect: DOMRect) {
    this.setState({
      helpMessage: {
        show: true,
        refElement: element,
        refElementRect: rect
      }
    });
  }

  hideHelpMessageElement() {
    if (this.state.helpMessage)
      this.setState(oldState => ({
        helpMessage: {
          ...oldState.helpMessage,
          show: false
        }
      }));
  }

  render() {
    return this.state.forbidden ?
      <h1 className='forbidden'>Нет доступа</h1>
      :
      <>
        <SearchTool onInput={this.inputKeyword.bind(this)} search={() => {
          this.setState({
            isEnd: false,
            page: 0
          });
          setTimeout(() => this.getData(true));
        }}/>
        <ParcelTableForUser
          data={this.state.rows}
          isEnd={this.state.isEnd}
          busy={this.state.busy}
          locationList={this.state.locations}
          loadMoreRows={this.getData.bind(this)}
          helpMessage={{
            define: this.defineHelpMessageElement.bind(this),
            hide: this.hideHelpMessageElement.bind(this)
          }}
        />
        {
          this.state.popUp ?
            <PopUp
              children={this.state.popUp}
              callback={this.closePopUp.bind(this)}
            />
            :
            <></>
        }
        {
          this.state.busy ?
            <div className='busy-window'>
              <div className='loader'>
                <Loader/>
              </div>
            </div>
            :
            <></>
        }
        <HelpMessage
          text={this.state.helpMessage.refElement?.getAttribute('data-hover')}
          {...this.state.helpMessage}
        />
      </>
  }
}