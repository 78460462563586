import React from "react";
import NotificationList from "../../../components/notification/list/NotificationList";
import NotificationService from "../../../services/NotificationService";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import UserTableContainer from "../../user/table/UserTableContainer";
import Loader from "../../../components/base/loader/Loader";
import PopUp from "../../../components/popUp/PopUp";
import NotificationControlBox from "../../../components/notification/controlBox/NotificationControlBox";
import UserService from "../../../services/UserService";

interface INotificationControlControllerState {
  rows: Array<{
    values: App.Notification.INotificationListData
    content: App.Notification.INotificationListContent
  }>
  users: Array<App.User.IUserData>
  busy: boolean
  forbidden: boolean
  popUp?: JSX.Element
  controlBoxLocationId?: number
}

export default class NotificationControlController extends React.Component<any, INotificationControlControllerState> {
  private userService: UserService;
  private notificationService: NotificationService;
  constructor(props: any) {
    super(props);

    this.state = {
      rows: [],
      users: [],
      busy: false,
      forbidden: false
    };

    this.notificationService = new NotificationService();
    this.userService = new UserService();
  }

  componentDidMount() {
    let access = localStorage.getItem('access_token');
    let role = localStorage.getItem('role');
    if (!access || !['super_admin'].includes(role || ''))
      this.setState({
        forbidden: true
      })
    else
      this.getData();
  }

  async getData() {
    if (this.state.busy)
      return;

    this.setState({
      busy: true
    });

    let result = await this.notificationService.list();

    if (result && result.status === 200) {
      if (Array.isArray(result.response)) {
        let rows: Array<{
          values: App.Notification.INotificationListData,
          content: App.Notification.INotificationListContent
        }> = [];

        result.response.forEach((element: App.IObject) => {
          let users: Array<App.Notification.INotificationUserContent> | undefined;
          if (element.users.length) {
            users = [];
            element.users.forEach((user: App.IObject) => {
              users?.push({
                location_user_id: user['location_user_id'] || 0,
                user_id: user['user_id'] || 0,
                user_email: user['user_email'] || '',
                user_role: UserTableContainer.userRoles[user['user_role']] || '',
                user_status: UserTableContainer.userStatuses[user['user_status']] || '',
              })
            });
          }

          rows.push({
            values: {
              location: {
                location_id: element.location_id,
                location_name: element.location_name,
                location_address: element.location_address,
                location_status: element.location_status
              },
              users: element.users.length ? element.users : undefined
            },
            content: {
              location: {
                location_id: element['location_id'] || '',
                location_name: element['location_name'] || '',
                location_address: element['location_address'] || '',
                location_status: element['location_status'] ? 'Активно' : 'Неактивно'
              },
              users: users
            }
          })
        });

        this.setState(state =>({
          rows: rows
        }));

      } else
        this.setState({
          popUp: <ErrorPopUp />
        });

    } else if (result && result.status === 403) {
      this.setState({
        forbidden: true
      })
    } else if (result)
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
    else
      this.setState({
        forbidden: true
      })

    this.setState({
      busy: false
    });
  }

  async getUsers() {
    if (this.state.busy)
      return;

    this.setState({
      busy: true
    });

    let result = await this.userService.list();

    if (result && result.status === 200) {
      if (Array.isArray(result.response)) {
        let users: Array<App.User.IUserData> = [];
        result.response.forEach(element => {
          users.push({
            user_id: element['user_id'],
            user_email: element['user_email'],
            user_role: element['user_role'],
            user_status: element['user_status'],
          })
        });
        this.setState({
          users: users
        })
      } else
        this.setState({
          popUp: <ErrorPopUp />
        });
    } else if (result)
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
    else
      this.setState({
        popUp: <ErrorPopUp />
      });

    this.setState({
      busy: false
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  async changeLocationForControlBox(locationId: number) {
    await this.getUsers();

    this.setState({
      controlBoxLocationId: locationId
    })
  }

  getUserOptions() {
    let userOptions: Array<App.User.IUserOption> = [];
    let selectedUsers = this.state.rows.find(element => element.values.location.location_id === this.state.controlBoxLocationId)?.values.users;

    this.state.users.forEach((element) => {
      userOptions.push({
        id: element.user_id,
        label: `${element.user_email} (${UserTableContainer.userRoles[element.user_role]}) - ${UserTableContainer.userStatuses[element.user_status]}`,
        disabled: selectedUsers?.find(user => user.user_id === element.user_id) !== undefined
      })
    });

    return userOptions;
  }

  async addNotification(locationId: number, userId: number) {
    let result = await this.notificationService.new(locationId, userId);
    this.handleLocationUserListLoadResult(locationId, result);
    return true;
  }

  async deleteNotification(locationUserId: number, locationId: number, userId: number) {
    let result = await this.notificationService.delete(locationUserId, locationId, userId);
    this.handleLocationUserListLoadResult(locationId, result);
    return true;
  }

  handleLocationUserListLoadResult(locationId: number, result: any) {
    if (result.status === 200) {
      if (Array.isArray(result.response))
        this.editLocationUserList(locationId, result.response.length ? result.response : undefined);
      else
        this.setState({
          popUp: <ErrorPopUp />
        })
    } else
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
  }

  async editLocationUserList(locationId: number, users?: Array<App.Notification.INotificationUserData>) {
    this.setState((oldState: INotificationControlControllerState) => {
      let newStateRows: Array<{
        values: App.Notification.INotificationListData
        content: App.Notification.INotificationListContent
      }> = JSON.parse(JSON.stringify(oldState.rows));
      let rowIndex = newStateRows.findIndex((element) => element.values.location.location_id === locationId);
      if (rowIndex !== -1) {
        newStateRows[rowIndex].values.users = users;
        if (users) {
          let usersContent: Array<App.Notification.INotificationUserContent> = [];
          users.forEach((user: App.Notification.INotificationUserData) => {
            usersContent.push({
              location_user_id: user['location_user_id'] || 0,
              user_id: user['user_id'] || 0,
              user_email: user['user_email'] || '',
              user_role: UserTableContainer.userRoles[user['user_role']] || '',
              user_status: UserTableContainer.userStatuses[user['user_status']] || '',
            })
          });
          newStateRows[rowIndex].content.users = usersContent;
        } else {
          newStateRows[rowIndex].content.users = undefined;
        }
      }

      return {
        rows: newStateRows
      };
    });
  }

  closeControlBox() {
    this.setState({
      controlBoxLocationId: undefined
    })
  }

  render() {
    let data: Array<App.Notification.INotificationListContent> = [];
    this.state.rows.forEach((element) => {
      data.push(element.content)
    });
    return this.state.forbidden ?
      <h1 className='forbidden'>Нет доступа</h1>
      :
      <div className={this.state.controlBoxLocationId ? 'extended' : ''}>
        <NotificationList
          data={data}
          select={this.changeLocationForControlBox.bind(this)}
        />
        {
          this.state.controlBoxLocationId ?
            <NotificationControlBox
              location={this.state.rows.find(element => element.values.location.location_id === this.state.controlBoxLocationId)?.content.location}
              users={{
                all: this.getUserOptions(),
                selected: this.state.rows.find(element => element.values.location.location_id === this.state.controlBoxLocationId)?.content.users
              }}
              addNotification={this.addNotification.bind(this)}
              deleteNotification={this.deleteNotification.bind(this)}
              closeControlBox={this.closeControlBox.bind(this)}
            />
            :
            <></>
        }
        {
          this.state.popUp ?
            <PopUp
              children={this.state.popUp}
              callback={this.closePopUp.bind(this)}
            />
            :
            <></>
        }
        {
          this.state.busy ?
            <div className='busy-window'>
              <div className='loader'>
                <Loader/>
              </div>
            </div>
            :
            <></>
        }
      </div>
  }
}