import Network from "../utils/Network";
import Token from "../utils/Token";

export default class NotificationService {
  network: Network;

  constructor() {
    this.network = Network.getInstance();
  }

  async list() {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return this.network.getRequest(`notifications`,'' , headers);
    }
  }


  async new(locationId: number, userId: number) {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return await this.network.postRequest(`notification/new`, {
        'location_id': locationId,
        'user_id': userId
      }, headers);
    }
  }

  async delete(locationUserId: number, locationId: number, userId: number) {
    let accessToken = await Token.access;
    if (!accessToken) return ;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return await this.network.postRequest(`notification/delete`, {
        'location_user_id': locationUserId,
        'location_id': locationId,
        'user_id': userId
      }, headers);
    }
  }
}