import React from "react";
import Chevron from '../../../../../assets/icons/chevron.svg';
import RowShortView from "./short/RowShortView";
import RowDetailedView from "./detailed/RowDetailedView";
import './ParcelRow.scss'

interface IParcelRowProps {
  requestId: number
  shortInfo: App.IObject
  locationList: Array<App.Location.ILocationOption>
  status: {
    value: string
    changeStatus: (requestId: number, status: string) => void
    acceptChanges: (requestId: number) => Promise<any>
  }
  mainInfo: {
    data: App.IObject
    edit: (requestId: number) => void
  }
  locationHistory: {
    data?: Array<App.Location.ILocationHistory>
    add: (requestId: number, locationId: number) => Promise<any>
    delete: (requestLocationId: number, requestId: number, locationId: number) => Promise<any>
    load: (requestId: number) => Promise<any>
  }
  helpMessage: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
}

interface IParcelRowState {
  opened: boolean
  loading: boolean
}

export default class ParcelRow extends React.Component<IParcelRowProps, IParcelRowState> {
  constructor(props: IParcelRowProps) {
    super(props);

    this.state = {
      opened: false,
      loading: false
    };
  }

  toggleView(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.opened)
      this.setState({
        opened: false
      });
    else {
      if (!this.props.locationHistory.data) {
        this.setState({
          opened: true,
          loading: true
        });
        this.props.locationHistory.load(this.props.requestId).then(
          () => this.setState({
            loading: false
          })
        );
      } else {
        this.setState({
          opened: true
        });
      }
    }
  }

  render() {
    let className = 'parcel-row';
    if (this.state.opened)
      className += ' opened';

    return <div className={className}>
      <div
        className='show-detailed-view'
        onClick={(event: React.MouseEvent<HTMLDivElement>) => this.toggleView(event)}
      >
        <div>
          {
            this.state.opened ? 'Свернуть' : 'Развернуть'
          }
        </div>
        <img src={Chevron} alt='toggle'/>
      </div>
      {
        this.state.opened ?
          <RowDetailedView
            {...this.props}
            loading={this.state.loading}
            helpMessage={this.props.helpMessage}
          />
          :
          <RowShortView
            data={this.props.shortInfo}
            helpMessage={this.props.helpMessage}
          />
      }
    </div>
  }
}