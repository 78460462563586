import React from "react";
import './NotificationListRow.scss'

interface INotificationRowProps {
  location: App.Location.ILocationContent
  users?: Array<App.Notification.INotificationUserContent>
  select: (locationId: number) => void
}

export default class NotificationListRow extends React.Component<INotificationRowProps, any>{
  getShortData() {
    let location = `${this.props.location.location_name} (${this.props.location.location_address})`;
    let userList = '';
    this.props.users?.forEach((element) => {
      userList += `${element.user_email}, `
    });
    userList = userList.substr(0, userList.length - 2);

    return [location, userList];
  }

  render() {
    let shortData = this.getShortData();

    return <div
      className='notification-list-row'
      onClick={() => this.props.select(this.props.location.location_id)}
    >
      <div className='location'>{shortData[0]}</div>
      <div className='users'>{shortData[1]}</div>
    </div>
  }
}