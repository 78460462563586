import React from "react";
import {RouteComponentProps} from "react-router";
import HeaderContainer from "../../containers/header/HeaderContainer";
import routes from "../routes";
import {toMain} from "../moves";

export default class HeaderView extends React.Component<RouteComponentProps> {
  getActive(pathname: string) {
    for (let field in routes) {
      if (routes.hasOwnProperty(field) && pathname.indexOf(routes[field]) !== -1) {
        return field;
      }
    }
    return '';
  }

  render() {
    return <HeaderContainer
      active={this.getActive(this.props.location.pathname)}
      toMain={() => toMain(this.props.history)}
    />
  }
}