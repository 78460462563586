import routes from './routes';

interface IHistory {
  push(url: string): void;
  replace(url: string): void;
}

function push(history: IHistory, url: string) {
  history.push(url);
}

function replace(history: IHistory, url: string) {
  history.replace(url);
}

export function toMain(h: IHistory, r?: boolean): void {
  r ? replace(h, routes.main) : push(h, routes.main);
}

export function toSignInPage(h: IHistory, r?: boolean): void {
  r ? replace(h, routes.signIn) : push(h, routes.signIn);
}

export function toErrorPage(h: IHistory, r?: boolean): void {
  r ? replace(h, routes.error) : push(h, routes.error);
}

export function toParcelPage(h: IHistory, hash: string, r?: boolean): void {
  r ? replace(h, `${routes.parcel}/${hash}`) : push(h, `${routes.parcel}/${hash}`);
}