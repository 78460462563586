import React from 'react';
import QuestionPopUp from "../../../../popUp/questionPopUp/QuestionPopUp";
import './NotificationUserTableRow.scss'

interface INotificationUserTableRowProps {
  locationId: number
  user: App.Notification.INotificationUserContent
  onClick: (locationUserId: number, locationId: number, userId: number) => void
}

interface INotificationUserTableRowState {
  popUp?: JSX.Element
}

export default class NotificationUserTableRow extends React.Component<INotificationUserTableRowProps, INotificationUserTableRowState> {
  constructor(props: INotificationUserTableRowProps) {
    super(props);

    this.state = {}
  }

  onClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.setState({
      popUp: <QuestionPopUp
        acceptAction={() => this.props.onClick(this.props.user.location_user_id, this.props.locationId, this.props.user.user_id)}
        declineAction={this.closePopUp.bind(this)}
      />
    })
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  render() {
    return <div className='notification-user-table-row'>
      <div className='label'>
        {`${this.props.user.user_email} (${this.props.user.user_role}) - ${this.props.user.user_status}`}
      </div>
      <div
        className='delete-user-notification-button'
        onClick={this.onClick.bind(this)}
      >
        {
          this.state.popUp ?
            this.state.popUp
            :
            <></>
        }
      </div>
    </div>
  }
}