import React from "react";
import PopUp from "../../../components/popUp/PopUp";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import AuthService from "../../../services/AuthService";
import SignInForm from "../../../components/form/signIn/SignInForm";
import {sha256} from "js-sha256";
import Page from "../../../utils/Page";

interface ISignInContainerProps {
  toMainPage: () => void
  initialValues?: App.IObject
}

interface ISignInContainerState {
  busy: boolean
  popUp?: JSX.Element
}

export default class SignInContainer extends React.Component<ISignInContainerProps, ISignInContainerState> {
  private authService: AuthService;
  constructor(props: ISignInContainerProps) {
    super(props);

    this.authService = new AuthService();

    Page.setTitle('Вход');

    this.state = {
      busy: false,
    }
  }

  sendData(data: App.IObject) {
    this.setState({
      busy: true
    });

    if (data.hasOwnProperty('password') && data['password']) {
      let salt = new Date().getTime();
      let hash = sha256.create().update(data['password']).hex();

      data['password'] = sha256.create().update(hash + salt).hex();
      data['salt'] = salt;
    }

    this.authService.signIn(data).then((result) => {
      if (result && result.status === 200) {
        localStorage.setItem('access_token', result.response['access']);
        localStorage.setItem('refresh_token', result.response['refresh']);
        localStorage.setItem('session', result.response['session_id']);

        let expIn = JSON.parse(atob(result.response['access'].split('.')[1]))['exp'];
        let role = JSON.parse(atob(result.response['access'].split('.')[1]))['role'];
        let email = JSON.parse(atob(result.response['access'].split('.')[1]))['email'];

        localStorage.setItem('exp', expIn);
        localStorage.setItem('role', role);
        localStorage.setItem('email', email);

        this.setState({
          busy: false
        });

        setTimeout(this.props.toMainPage);
      } else if (result) {
        this.setState({
          popUp: <ErrorPopUp errors={result.response} />,
          busy: false
        });
      } else {
        this.setState({
          popUp: <ErrorPopUp />,
          busy: false
        });
      }
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    });
  }

  render() {
    return <>
      <SignInForm
        onSubmit={this.sendData.bind(this)}
        busy={this.state.busy}
        initialValues={this.props.initialValues}
      />
      {
        this.state.popUp ?
          <PopUp
            children={this.state.popUp}
            callback={this.closePopUp.bind(this)}
          />
          :
          <></>
      }
    </>
  }
}