import LocationService from "../services/LocationService";

export default class Location {
  static async getLocationOptions() {
    let locationService = new LocationService();
    let locations: Array<App.Location.ILocationOption> = [];

    let result = await locationService.list();

    if (result && result.status === 200) {
      result.response.forEach((element: App.Location.ILocationData) => {
        locations.push({
          id: element.location_id,
          label: `${element.location_name} (${element.location_address})`,
          status: element.location_status
        })
      });
    }

    return locations;
  }
}