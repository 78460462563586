import React from "react";
import {RouteComponentProps} from "react-router";
import ParcelTableContainer from "../../../containers/parcel/table/ParcelTableContainer";
import {toParcelPage} from "../../moves";
import './ParcelTableView.scss'
import Page from "../../../utils/Page";
import {mode} from "../../../utils/Config";

export default class ParcelTableView extends React.Component<RouteComponentProps>{
  componentDidMount(): void {
    Page.setTitle('Посылки');
    // @ts-ignore
    if (mode === 'prod')
      (async () => {
        await new Promise(() => {
          let interval = setInterval(() => {
            let captcha = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>;
            if (captcha[0]) {
              captcha[0].style.opacity = "0";
              clearInterval(interval);
            }
          }, 1000)
        })
      })()
  }

  render() {
    return <div className='parcel-table-view'>
      <ParcelTableContainer toParcelPage={(hash: string) => toParcelPage(this.props.history, hash)}/>
    </div>
  }
}