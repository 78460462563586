import React from "react";
import ParcelForUserTable from "../../ParcelForUserTable";
import './ParcelForUserTableTitles.scss'

export default class ParcelForUserTableTitles extends React.Component<any, any> {
  render() {
    let titles = [];
    for (let key in ParcelForUserTable.parcelForUserTableTitles) {
      if (ParcelForUserTable.parcelForUserTableTitles.hasOwnProperty(key)) {
        titles.push((<div key={key} className={key}>
          {
            // @ts-ignore
            ParcelForUserTable.parcelForUserTableTitles[key]
          }
        </div>));
      }
    }

    return <div className='user-parcel-table-titles'>{titles}</div>
  }
}