import React from "react";
import LocationTable from "../../table/location/LocationTable";
import './NotificationControlBox.scss'
import UserAddTool from "../../userAddTool/UserAddTool";
import NotificationUserTable from "../../table/notificationUser/NotificationUserTable";

interface INotificationControlBoxProps {
  location?: App.Location.ILocationContent
  users: {
    all?: Array<App.User.IUserOption>
    selected?: Array<App.Notification.INotificationUserContent>
  }
  addNotification: (locationId: number, userId: number) => void
  deleteNotification: (locationUserId: number, locationId: number, userId: number) => void
  closeControlBox: () => void
}

interface INotificationControlBoxState {
  busy: boolean
}

export default class NotificationControlBox extends React.Component<INotificationControlBoxProps, INotificationControlBoxState> {
  constructor(props: INotificationControlBoxProps) {
    super(props);

    this.state = {
      busy: false
    }
  }

  async addNotification(locationId: number, userId: number) {
    if (this.state.busy)
      return;

    this.setState({
      busy: true
    });

    await this.props.addNotification(locationId, userId);

    this.setState({
      busy: false
    })
  }

  async deleteNotification(locationUserId: number, locationId: number, userId: number) {
    if (this.state.busy)
      return;

    this.setState({
      busy: true
    });

    await this.props.deleteNotification(locationUserId, locationId, userId);

    this.setState({
      busy: false
    })
  }

  render() {
    if (this.props.location) {
      let cells = [];
      for (let key in LocationTable.locationTableTitles) {
        if (this.props.location.hasOwnProperty(key) && LocationTable.locationTableTitles.hasOwnProperty(key)) {
          cells.push((
            <div key={key} className={`location-info-row ${key}`}>
              <div className='label'>
                {
                  // @ts-ignore
                  `${LocationTable.locationTableTitles[key]}:`
                }
              </div>
              <div className='value'>
                {
                  // @ts-ignore
                  this.props.location[key]
                }
              </div>
            </div>
          ));
        }
      }
      return <div className='notification-control-box'>
        <div className='location-info-container'>
          <div className='location-info-label'>Информация о местоположении</div>
          <div className='location-info-content'>{cells}</div>
        </div>
        <UserAddTool
          locationId={this.props.location?.location_id}
          userList={this.props.users.all}
          busy={this.state.busy}
          add={this.addNotification.bind(this)}
        />
        <div className='notification-user-table-label'>Пользователи, получающие уведомления</div>
        <NotificationUserTable
          locationId={this.props.location?.location_id}
          users={this.props.users.selected}
          delete={this.deleteNotification.bind(this)}
        />
        <div
          className='close-control-box'
          onClick={this.props.closeControlBox}
        />
      </div>
    } else {
      return <></>
    }
  }
}