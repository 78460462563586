import Network from "../utils/Network";
import Token from "../utils/Token";
import {mode} from "../utils/Config";

export default class ParcelService {
  private network: Network;
  constructor() {
    this.network = Network.getInstance();
  }

  async new(data: App.IObject) {
    // @ts-ignore
    if (mode === 'prod')
      // @ts-ignore
      data['captcha'] = await grecaptcha.execute('6LfqTrgUAAAAAM48tLy4TBsSauz1z1P3tFytrWWC', {action: 'send_form'});
    return await this.network.postRequest('parcel/new', data);
  }

  async edit(data: App.IObject, requestId: number) {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return this.network.postRequest(`parcel/edit/${requestId}`, data, headers);
    }
  }

  async list(param: App.IObject) {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      let params = `page=${param.page}&amount=${param.amount}&column=${param.column}&order=${param.order}`;
      if (param.keyword)
        params += `&keyword=${param.keyword}`;

      return this.network.getRequest('parcels', params, headers);
    }
  }

  async listWithChanges(param: App.IObject) {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {
      let params = `page=${param.page}&amount=${param.amount}`;

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return this.network.getRequest('change-list', params, headers);
    }
  }

  async parcelForUserList(param: App.IObject) {
    let accessToken = await Token.access;
    if (!accessToken) return;
    else {
      let params = `page=${param.page}&amount=${param.amount}`;
      if (param.keyword)
        params += `&keyword=${param.keyword}`;

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return this.network.getRequest('parcels/self', params, headers);
    }
  }

  async getParcelLocationHistory(requestId: number) {
    return  await this.network.getRequest(`history?id=${requestId}`, '');
  }

  async editStatus(requestId: number, status: string) {
    let accessToken = await Token.access;
    if (!accessToken)
      return ;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return await this.network.postRequest(`parcel/status/update`, {
        'request_id': requestId,
        'status': status
      }, headers);
    }
  }

  async addToLocationHistory(requestId: number, locationId: number) {
    let accessToken = await Token.access;
    if (!accessToken)
      return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return await this.network.postRequest(`history/new`, {
        'request_id': requestId,
        'location_id': locationId
      }, headers);
    }
  }

  async deleteFromLocationHistory(requestLocationId: number, requestId: number, locationId: number) {
    let accessToken = await Token.access;
    if (!accessToken)
      return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return await this.network.postRequest(`history/delete`, {
        'request_location_id': requestLocationId,
        'request_id': requestId,
        'location_id': locationId
      }, headers);
    }
  }

  async parcelData(requestHash: string) {
    let params = `hash=${requestHash}`;
    return await this.network.getRequest('parcel', params);
  }

  async amountOfChanges() {
    let accessToken = await Token.access;
    if (!accessToken)
      return;
    else {

      let headers = {
        'Authorization': `Bearer ${accessToken}`,
      };

      return await this.network.getRequest(`amount-of-changes`, '', headers);
    }
  }
}