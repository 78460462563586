import React from "react";
import PopUp from "../../../components/popUp/PopUp";
import ErrorPopUp from "../../../components/popUp/errorPopUp/ErrorPopUp";
import AuthService from "../../../services/AuthService";
import SignUpForm from "../../../components/form/signUp/SignUpForm";
import {sha256} from "js-sha256";
import Page from "../../../utils/Page";

interface ISignUpContainerProps {
  initialValues?: App.IObject
}

interface ISignUpContainerState {
  busy: boolean
  popUp?: JSX.Element
}

export default class SignUpContainer extends React.Component<ISignUpContainerProps, ISignUpContainerState> {
  private authService: AuthService;
  constructor(props: ISignUpContainerProps) {
    super(props);

    this.authService = new AuthService();

    Page.setTitle('Регистрация');

    this.state = {
      busy: false,
    }
  }

  sendData(data: App.IObject) {
    this.setState({
      busy: true
    });

    if (data.hasOwnProperty('password') && data['password']) {
      data['password'] = sha256.create().update(data['password']).hex();
      data['repeat_password'] = sha256.create().update(data['repeat_password']).hex();
    }

    this.authService.signUp(data).then((result) => {
      if (result) {
        this.setState({
          popUp: <ErrorPopUp errors={result.response} />,
          busy: false
        });
      } else {
        this.setState({
          popUp: <ErrorPopUp />,
          busy: false
        });
      }
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    });
  }

  render() {
    return <>
      <SignUpForm
        onSubmit={this.sendData.bind(this)}
        busy={this.state.busy}
        initialValues={this.props.initialValues}
      />
      {
        this.state.popUp ?
          <PopUp
            children={this.state.popUp}
            callback={this.closePopUp.bind(this)}
          />
          :
          <></>
      }
    </>
  }
}