import React from "react";
import Select from "../base/customInput/select/Select";
import Button from "../base/button/Button";
import './LocationAddTool.scss'
import Loader from "../base/loader/Loader";

interface ILocationAddToolProps {
  requestId: number
  locationList?: Array<App.Location.ILocationOption>
  busy: boolean
  add: (requestId: number, locationId: number) => void
}

interface ILocationAddToolState {
  selected: number
  disabled: boolean
}

export default class LocationAddTool extends React.Component<ILocationAddToolProps, ILocationAddToolState> {
  constructor(props: ILocationAddToolProps) {
    super(props);

    this.state = {
      selected: 0,
      disabled: true
    }
  }

  changeSelectedLocation(name: string, value: string | number) {
    this.setState({
      selected: +value,
      disabled: !+value
    })
  }

  render() {
    let options: Array<App.CustomSelect.IOption> = [{
      id: 0,
      label: 'Выберите местоположение',
      disabled: true
    }];
    this.props.locationList?.forEach((location) => {
      if (location.status)
        options.push({
          id: location.id,
          label: location.label
        })
    });
    return <div className='location-add-tool'>
      <Select
        value={this.state.selected}
        name='location'
        options={options}
        onChange={this.changeSelectedLocation.bind(this)}
      />
      {
        this.props.busy ?
          <div className='add-location-loader'><div className='loader'><Loader/></div></div>
          :
          <Button
            disabled={this.state.disabled}
            question={true}
            value='Добавить'
            onClick={() => this.props.add(this.props.requestId, this.state.selected)}
          />
      }
    </div>
  }
}