import React from "react";
import './QuestionPopUp.scss'
import Button from "../../base/button/Button";
import PopUp from "../PopUp";

interface IQuestionPopUpProps {
  acceptAction: () => void
  declineAction: () => void
}

export default class QuestionPopUp extends React.Component<IQuestionPopUpProps, any> {
  render() {
    return <PopUp callback={this.props.declineAction}>
      <div className='question-container'>
        <h1 className="question-text">Вы действительно<br/> хотите продолжить?</h1>
        <div className='button-container'>
          <Button
            value='Да'
            className='button-accept'
            onClick={() => {
              this.props.acceptAction();
              this.props.declineAction();
            }}
          />
          <Button
            value='Нет'
            className='button-decline'
            onClick={this.props.declineAction}
          />
        </div>
      </div>
    </PopUp>
  }
}