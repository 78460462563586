import React from "react";
import Validator from "../../../utils/Validator";
import CustomInput from "../../base/customInput/CustomInput";
import Loader from "../../base/loader/Loader";
import Button from "../../base/button/Button";
import UserTableContainer from "../../../containers/user/table/UserTableContainer";
import QuestionPopUp from "../../popUp/questionPopUp/QuestionPopUp";
import '../Form.scss'

interface IUserEditFormProps {
  initialValues?: App.User.IUserData
  busy: boolean
  onSubmit: (data: App.IObject) => void
}

interface IUserEditFormState {
  inputs: {
    [key: string]: App.Form.IInputData
  }
  popUp?: JSX.Element
}

export default class UserEditForm extends React.Component<IUserEditFormProps, IUserEditFormState>{
  static userRole = ['user', 'admin', 'super_admin'];
  static userStatus = ['active', 'queued', 'inactive'];

  constructor(props: IUserEditFormProps) {
    super(props);

    let userStatusOptions: Array<App.CustomSelect.IOption> = [];

    for (let key in UserTableContainer.userStatuses) {
      if (UserTableContainer.userStatuses.hasOwnProperty(key))
        userStatusOptions.push({
          id: key,
          label: UserTableContainer.userStatuses[key],
          disabled: key === 'queued'
        })
    }

    let userRoleOptions: Array<App.CustomSelect.IOption> = [];

    for (let key in UserTableContainer.userRoles) {
      if (UserTableContainer.userRoles.hasOwnProperty(key))
        userRoleOptions.push({
          id: key,
          label: UserTableContainer.userRoles[key]
        })
    }

    this.state = {
      inputs: {
        'user_email': {
          label: 'Почта:',
          tip: 'Пожалуйста, введите почту',
          type: 'email',
          valueType: 'email',
          required: true,
          value: this.props.initialValues?.user_email || '',
          valid: this.props.initialValues?.user_email ? Validator.validate(this.props.initialValues?.user_email, 'text') : undefined
        },
        'user_role': {
          label: 'Роль:',
          tip: 'Пожалуйста, выберите роль',
          type: 'select',
          valueType: 'select',
          required: true,
          options: userRoleOptions,
          value: this.props.initialValues?.user_role || 'user',
          valid: this.props.initialValues?.user_role ? UserEditForm.userRole.includes(this.props.initialValues?.user_role) : true
        },
        'user_status': {
          label: 'Статус:',
          tip: 'Пожалуйста, выберите статус',
          type: 'select',
          valueType: 'select',
          required: true,
          options: userStatusOptions,
          value: this.props.initialValues?.user_status || 'active',
          valid: this.props.initialValues?.user_status ? UserEditForm.userStatus.includes(this.props.initialValues?.user_status) : true
        },
      }
    }
  }

  changeFormInputState(name: string, value: string | number) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].value = value;

      if (newStateInputs[name].valueType === 'select') {
        Object.keys(newStateInputs).forEach(key => {
          if (newStateInputs[key].valueType === 'select' && key !== name) {
            newStateInputs[key].options?.forEach((option: App.CustomSelect.IOption) => {
              option.selected = +option.id === +value;
            })
          }
        });
      }

      return {
        inputs: newStateInputs
      };
    });
  }

  removeValidation(name: string) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].valid = undefined;
      return {
        inputs: newStateInputs
      };
    })
  }

  setValidation(name: string) {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      newStateInputs[name].valid = this.validateInput(name, newStateInputs[name].value, newStateInputs[name].valueType, newStateInputs[name].required);
      return {
        inputs: newStateInputs
      };
    });
  }

  showFormState() {
    this.setState((oldState) => {
      let newStateInputs = JSON.parse(JSON.stringify(oldState.inputs));
      for (let name in newStateInputs) {
        if (newStateInputs.hasOwnProperty(name)) {
          newStateInputs[name].valid = this.validateInput(name, newStateInputs[name].value, newStateInputs[name].valueType, newStateInputs[name].required);
        }
      }
      return {
        inputs: newStateInputs
      };
    });
  }

  validateInput(name: string, value: number | string, valueType: string, required: boolean) {
    if (name === 'user_role')
      return UserEditForm.userRole.includes(String(value));
    else if (name === 'user_status')
      return UserEditForm.userStatus.includes(String(value));
    else if (((typeof value !== 'number') && value) || (typeof value === 'number'))
      return Validator.validate(value, valueType);
    else
      return !required;
  }

  getFormStateAndValues() {
    let formState = true;
    let formValues: App.IObject = {};
    for (let key in this.state.inputs) {
      if (this.state.inputs.hasOwnProperty(key)) {
        formState = formState && !!this.state.inputs[key].valid;
        formValues[key] = this.state.inputs[key].value;
      }
    }
    return {
      'state': formState,
      'values': formValues
    };
  }

  submit(event: React.FormEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showFormState();
    setTimeout(() => {
      let formStateAndValues = this.getFormStateAndValues();
      if (formStateAndValues.state) {
        this.setState({
          popUp: <QuestionPopUp
            acceptAction={() => this.props.onSubmit(formStateAndValues.values)}
            declineAction={this.closePopUp.bind(this)}
          />
        })
      }
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }

  render() {
    return <form
      onSubmit={this.submit.bind(this)}
    >
      <fieldset>
        <legend>
          {`Пользовтель №${this.props.initialValues?.user_id}`}
        </legend>
        {
          Object.keys(this.state.inputs).map((name) => {
            const state = this.state.inputs[name];
            return <CustomInput
              key={name}
              name={name}
              {...state}
              onFocus={this.removeValidation.bind(this)}
              onBlur={this.setValidation.bind(this)}
              onChange={this.changeFormInputState.bind(this)}
            />
          })
        }
      </fieldset>
      {
        this.props.busy ?
          <div className='loader'>
            <Loader/>
          </div>
          :
          <Button
            className='submit'
            submit={true}
            value='Сохранить'
          />
      }
      {
        this.state.popUp ?
          this.state.popUp
          :
          <></>
      }
    </form>
  }
}