import React from "react";
import ParcelService from "../../services/ParcelService";
import ErrorPopUp from "../../components/popUp/errorPopUp/ErrorPopUp";
import PopUp from "../../components/popUp/PopUp";
import Loader from "../../components/base/loader/Loader";
import ChangeList from "../../components/changeList/ChangeList";
import Location from "../../utils/Location";

interface IParcelTableContainerState {
  isEnd: boolean
  page: number
  amount: number
  busy: boolean
  forbidden: boolean
  rows: Array<App.ChangeList.IChangeListRowState>
  popUp?: JSX.Element
  locations: Array<App.Location.ILocationOption>
}

export default class ChangeListContainer extends React.Component<any, IParcelTableContainerState> {
  private parcelService: ParcelService;

  constructor(props: IParcelTableContainerState) {
    super(props);

    this.parcelService = new ParcelService();

    this.state = {
      isEnd: false,
      page: 0,
      amount: 30,
      busy: false,
      forbidden: false,
      rows: [],
      locations: [],
    }
  }

  componentDidMount(): void {
    Location.getLocationOptions().then(async result => {
      this.setState({
        locations: result
      });
      await this.getData();
    })
  }

  async getData() {
    if (this.state.busy || this.state.isEnd)
      return;

    this.setState({
      busy: true
    });

    let result = await this.parcelService.listWithChanges({
      page: this.state.page,
      amount: this.state.amount
    });

    if (result && result.status === 200) {
      if (Array.isArray(result.response)) {
        let isEnd = !result.response.length;
        let page = this.state.page + 1;

        this.setState(state =>({
          isEnd: isEnd,
          page: page,
          rows: state.rows.concat(result?.response)
        }));

      } else
        this.setState({
          popUp: <ErrorPopUp />
        });

    } else if (result && result.status === 403) {
      this.setState({
        forbidden: true
      })
    } else if (result)
      this.setState({
        popUp: <ErrorPopUp errors={result.response} />
      });
    else
      this.setState({
        forbidden: true
      })

    this.setState({
      busy: false
    });
  }

  closePopUp() {
    this.setState({
      popUp: undefined
    })
  }


  render() {
    return this.state.forbidden ?
      <h1 className='forbidden'>Нет доступа</h1>
      :
      <>
        <ChangeList
          list={this.state.rows}
          isEnd={this.state.isEnd}
          busy={this.state.busy}
          loadMore={this.getData.bind(this)}
        />
        {
          this.state.popUp ?
            <PopUp
              children={this.state.popUp}
              callback={this.closePopUp.bind(this)}
            />
            :
            <></>
        }
        {
          this.state.busy ?
            <div className='busy-window'>
              <div className='loader'>
                <Loader/>
              </div>
            </div>
            :
            <></>
        }
      </>
  }
}