import React from "react";
import LocationTableRow from "./components/locationTableRow/LocationTableRow";
import Button from "../../base/button/Button";
import LocationTableTitles from "./components/locationTableTitles/LocationTableTitles";

interface ILocationTableProps {
  data: Array<{
    values: App.Location.ILocationData,
    content: App.Location.ILocationContent
  }>
  addEdit: (locationId?: number) => void
  helpMessage: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
}

export default class LocationTable extends React.Component<ILocationTableProps, any>{
  static locationTableTitles = {
    'location_id': 'Номер',
    'location_name': 'Название',
    'location_address': 'Адрес',
    'location_status': 'Статус',
  };

  render() {
    return <div className='location-table-container'>
      <LocationTableTitles/>
      <div className='location-table'>
        {
          this.props.data.map((data, index) => {
            return <LocationTableRow
              key={index}
              data={data.content}
              edit={this.props.addEdit}
              helpMessage={this.props.helpMessage}
            />
          })
        }
      </div>
      <Button
        className='add-location'
        value='Добавить'
        onClick={() => this.props.addEdit()}
      />
    </div>
  }
}