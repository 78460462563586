import React from "react";
import './Loader.scss'

export default class Loader extends React.Component<any, any>{
  render() {
    return <div className='lds-ring'>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  }
}