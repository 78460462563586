import React from "react";
import StatusEditTool from "../../../../../statusEditTool/StatusEditTool";
import MainInfo from "../../../../../mainInfo/MainInfo";
import Button from "../../../../../base/button/Button";
import LocationHistoryTable from "../../../../../locationHistory/LocationHistoryTable";
import LocationAddTool from "../../../../../locationAddTool/LocationAddTool";
import ParcelPageLink from "../../../../../parcelPageLink/ParcelPageLink";
import './RowDetailedView.scss'

interface IRowDetailedViewProps {
  requestId: number
  status: {
    value: string
    changeStatus: (requestId: number, status: string) => void
    acceptChanges: (requestId: number) => Promise<any>
  }
  mainInfo: {
    data: App.IObject
    edit: (requestId: number) => void
  }
  locationHistory: {
    data?: Array<App.Location.ILocationHistory>
    add: (requestId: number, locationId: number) => Promise<any>
    delete: (requestLocationId: number, requestId: number, locationId: number) => Promise<any>
  }
  helpMessage: {
    define: (element: Element, rect: DOMRect) => void
    hide: () => void
  }
  locationList: Array<App.Location.ILocationOption>
  loading: boolean
}

interface IRowDetailedViewState {
  locationHistoryBusy: boolean
}

export default class RowDetailedView extends React.Component<IRowDetailedViewProps, IRowDetailedViewState>{
  constructor(props: IRowDetailedViewProps) {
    super(props);

    this.state = {
      locationHistoryBusy: false
    };
  }

  async addToLocationHistory(requestId: number, locationId: number) {
    if (this.state.locationHistoryBusy) return ;

    this.setState({
      locationHistoryBusy: true
    });

    await this.props.locationHistory.add(requestId, locationId);

    this.setState({
      locationHistoryBusy: false
    });
  }

  async deleteFromLocationHistory(requestLocationId: number, requestId: number, locationId: number) {
    if (this.state.locationHistoryBusy) return ;

      this.setState({
      locationHistoryBusy: true
    });

    await this.props.locationHistory.delete(requestLocationId, requestId, locationId);

    this.setState({
      locationHistoryBusy: false
    });
  }

  render() {
    return <div className='row-detailed-view'>
      <div className='status-bar-main-info-container'>
        <StatusEditTool
          requestId={this.props.requestId}
          {...this.props.status}
          />
          <MainInfo
            data={this.props.mainInfo.data}
            helpMessage={this.props.helpMessage}
          />
          <Button
            value='Редактировать'
            onClick={() => this.props.mainInfo.edit(this.props.requestId)}
          />
      </div>
      <div className='location-history-container'>
        <div className='location-history-title'>История передвижения посылки</div>
        <LocationAddTool
          requestId={this.props.requestId}
          locationList={this.props.locationList}
          busy={this.state.locationHistoryBusy}
          add={this.addToLocationHistory.bind(this)}
        />
        <LocationHistoryTable
          requestId={this.props.requestId}
          data={this.props.locationHistory.data}
          loading={this.props.loading}
          delete={this.deleteFromLocationHistory.bind(this)}
          deleteAllowed={true}
          helpMessage={this.props.helpMessage}
        />
      </div>
      <ParcelPageLink hash={this.props.mainInfo.data['request_hash']}/>
    </div>
  }
}